import React, { useEffect } from 'react';
import { View, Text } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { ActivityIndicator } from 'react-native';
import tw from '../../lib/tailwind';

export default function Home({ navigation }) {
    useEffect(() => {
        navigation.goBack();
    }, []);

    return (
        <SafeAreaView
            edges={['left', 'right']}
            style={tw.style('flex-1 flex items-center justify-center')}
        >
            <ActivityIndicator size="large" color={tw.color('accent')} />
        </SafeAreaView>
    );
}
