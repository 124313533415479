import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import React, {useEffect} from 'react';
import {View, Text, TouchableOpacity, Alert} from 'react-native';
import Home from './home';
import USend from './u-send';
import {useDispatch, useSelector} from 'react-redux';
import tw from '../../lib/tailwind';
import {
	selectLoggedInState,
	selectToken,
	selectUserInfo,
	setUserInformation,
	setToken,
} from '../../slices/userSlice';
import {Avatar, Icon} from 'react-native-elements';
import TabBar from '../../uiKit/tabBar';
import IndexUMission from './u-mission';
import UBringIndex from './u-bring';
import IndexUandMe from './uandme';
import {useIsFocused} from '@react-navigation/native';
import axios from 'axios';
import * as SecureStore from 'expo-secure-store';

export default function Index({navigation}) {
	const Tab = createBottomTabNavigator();

	const dispatch = useDispatch();

	const userInfo = useSelector(selectUserInfo);
	const loggedIn = useSelector(selectLoggedInState);
	const token = useSelector(selectToken);

	let timeoutRunning = false;

	const isFocused = useIsFocused();

	useEffect(() => {
		if (!loggedIn || token === null || userInfo === null) {
			dispatch(setUserInformation(null));
			dispatch(setToken(null));
			navigation.navigate('start', {merge: true});
		}
	}, [userInfo, loggedIn, token]);

	useEffect(() => {
		if (timeoutRunning) return;
		timeoutRunning = true;

		let timeout = setTimeout(() => {
			checkCurrentUser();
			timeoutRunning = false;
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	});

	async function checkCurrentUser() {
		console.log('checkCurrentUser', userInfo);
		if (userInfo.email == null) return;
		try {
			let res = await axios.post(
				process.env.APIURL + 'checkUser',
				{
					email: userInfo.email,
				},
				{
					headers: {
						'Authorization': 'Bearer ' + token,
					},
				}
			);
		} catch (error) {
			console.log(error.response.data);
			if (
				error.response.status == 401 ||
				(error.response.status == 403 &&
					error.response.data.message == 'user_does_not_exist')
			) {
				try {
					let res = await axios.post(
						process.env.APIURL + 'logout',
						{},
						{
							'headers': {
								'Authorization': 'Bearer ' + token,
							},
						}
					);
					await SecureStore.deleteItemAsync('ubring_login');
				} catch (error) {
					console.log(error);
				}
				navigation.navigate('start', {merge: true});
				dispatch(setUserInformation(null));
				dispatch(setToken());

				Alert.alert(
					'Sitzung abgelaufen',
					'Deine Sitzung ist abgelaufen. Bitte logge dich erneut ein.',
					[
						{
							text: 'OK',
							onPress: () => {
								navigation.navigate('start', {merge: true});
							},
						},
					]
				);
			}
		}
	}

	return (
		<Tab.Navigator
			screenOptions={{
				headerStyle: {
					backgroundColor: tw.color('primary'),
				},
				headerTitleStyle: {
					color: tw.color('white'),
					fontSize: 24,
				},
				headerTitleAlign: 'center',
				headerLeft: () => (
					<>
						{/*<TouchableOpacity
						style={tw.style('px-2')}
						onPress={() => navigation.goBack()}
					>
						<Icon name="chevron-left" size={32} color={tw.color('white')} />
					</TouchableOpacity>*/}
					</>
				),
				headerRight: () => (
					<TouchableOpacity
						style={tw.style('px-2')}
						onPress={() =>
							navigation.navigate('authenticatedContainer', {
								screen: 'uAndMe',
							})
						}
					>
						<Avatar
							rounded
							size={32}
							source={{
								uri:
									userInfo.avatarSrc !== ''
										? userInfo.avatarSrc
										: 'https://api.multiavatar.com/' + userInfo.email + '.png',
							}}
						/>
					</TouchableOpacity>
				),
				tabBarActiveTintColor: tw.color('primary'),
				tabBarInactiveTintColor: tw.color('gray-300'),
				tabBarHideOnKeyboard: true,
			}}
			tabBar={(props) => <TabBar {...props} />}
			detachInactiveScreens={true}
			backBehavior={'none'}
		>
			<Tab.Screen name="Home" component={Home} options={{title: 'Home'}} />
			<Tab.Screen name="uSend" component={USend} options={{title: 'U-Send'}} />
			<Tab.Screen
				name="uBring"
				component={UBringIndex}
				options={{title: 'U-Bring'}}
			/>
			<Tab.Screen
				name="uMission"
				component={IndexUMission}
				options={{title: 'U-Mission'}}
			/>
			<Tab.Screen
				name="uAndMe"
				component={IndexUandMe}
				options={{
					title: 'U-AndMe',
				}}
			/>
		</Tab.Navigator>
	);
}
