import React, {useEffect, useState} from 'react';
import {
	View,
	Text,
	KeyboardAvoidingView,
	Platform,
	Dimensions,
} from 'react-native';
import {CheckBox, Icon} from 'react-native-elements';
import {useDispatch, useSelector} from 'react-redux';
import tw from '../../../lib/tailwind';
import {selectHeaderBarLayout} from '../../../slices/headerBarSlice';
import {setNameAndCompany} from '../../../slices/registerUserSlice';
import BackButton from '../../../uiKit/backButton';
import Input from '../../../uiKit/input';
import NextButton from '../../../uiKit/nextButton';

import * as uuidV4 from 'uuid';
import KeyboardSpacer from 'react-native-keyboard-spacer';
import {MaterialIcons} from '@expo/vector-icons';

export default function NameForm({navigation}) {
	const dispatch = useDispatch();

	const [lastname, setLastname] = useState('');
	const [firstname, setFirstname] = useState('');
	const [isCompany, setIsCompany] = useState(false);
	const [companyName, setCompanyName] = useState('');

	const [firstnameError, setFirstnameError] = useState(false);
	const [lastnameError, setLastnameError] = useState(false);
	const [companyNameError, setCompanyNameError] = useState(false);

	const [buttonDisabled, setButtonDisabled] = useState(false);

	function verifyData() {
		if (firstname.trim().length == 0) {
			setFirstnameError('Bitte gib deinen Vornamen ein.');
			return;
		} else {
			setFirstnameError(false);
		}

		if (lastname.trim().length == 0) {
			setLastnameError('Bitte gib deinen Nachnamen ein.');
			return;
		} else {
			setLastnameError(false);
		}

		if (isCompany && companyName.trim().length == 0) {
			setCompanyNameError('Bitte gib den Firmennamen ein.');
			return;
		} else {
			setCompanyNameError(false);
		}

		saveEntry();
	}

	function updateCompanyName(newValue) {
		if (newValue !== '') {
			setIsCompany(true);
		} else {
			setIsCompany(false);
		}

		setCompanyName(newValue);
	}

	function saveEntry() {
		if (!buttonDisabled) {
			dispatch(
				setNameAndCompany({
					firstname,
					lastname,
					isCompany,
					companyName,
					username: 'user-' + uuidV4.v4(),
				})
			);
		}
		navigation.push('addressForm');
	}

	return (
		<View
			style={tw.style('w-full flex-1 flex justify-between items-center px-6')}
		>
			<View style={tw.style('w-full')}>
				<Text
					style={tw.style('text-xl font-bold text-gray-700 text-center my-7')}
				>
					Bitte gib deinen Namen ein.
				</Text>
				<Input
					placeholder="Vorname"
					onChangeText={(event) => setFirstname(event)}
					value={firstname}
					width="full"
					error={firstnameError}
				/>
				<Input
					placeholder="Nachname"
					onChangeText={(event) => setLastname(event)}
					value={lastname}
					width="full"
					error={lastnameError}
				/>
				<View
					style={tw.style('w-full flex flex-row justify-between items-center')}
				>
					<View style={tw.style('flex flex-row justify-between items-center')}>
						<Text style={tw.style('font-semibold text-gray-800')}>Firma?</Text>
						<CheckBox
							uncheckedIcon={
								<MaterialIcons
									name="radio-button-unchecked"
									color={tw.color('primary')}
								/>
							}
							checkedIcon={
								<MaterialIcons
									name="radio-button-checked"
									color={tw.color('primary')}
								/>
							}
							checked={isCompany}
							center
							onPress={() => setIsCompany(!isCompany)}
						/>
					</View>
					<Input
						placeholder="Firmenname"
						onChangeText={(event) => updateCompanyName(event)}
						value={companyName}
						width="2/3"
						error={companyNameError}
					/>
				</View>
			</View>
			<View style={tw.style('mb-6')}>
				<View style={tw.style('flex flex-row justify-center w-full ')}>
					<BackButton onPress={() => navigation.goBack()} />
					<NextButton
						onPress={() => verifyData()}
						label="Weiter"
						showIcon
						disabled={buttonDisabled}
					/>
				</View>

				{Platform.OS === 'ios' && <KeyboardSpacer />}
			</View>
		</View>
	);
}
