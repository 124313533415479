import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {
	View,
	Text,
	KeyboardAvoidingView,
	Platform,
	Dimensions,
} from 'react-native';
import {CheckBox, Icon} from 'react-native-elements';
import {GooglePlacesAutocomplete} from 'react-native-google-places-autocomplete';
import {useDispatch, useSelector} from 'react-redux';
import tw from '../../../lib/tailwind';
import {selectHeaderBarLayout} from '../../../slices/headerBarSlice';
import {setAddressInformation} from '../../../slices/registerUserSlice';
import BackButton from '../../../uiKit/backButton';
import Input from '../../../uiKit/input';
import NextButton from '../../../uiKit/nextButton';
import uuid from 'uuid';
import KeyboardSpacer from 'react-native-keyboard-spacer';

export default function AdressForm({navigation}) {
	const dispatch = useDispatch();

	const HeaderBarLayout = useSelector(selectHeaderBarLayout);

	const [location, setLocation] = useState(null);

	const [buttonDisabled, setButtonDisabled] = useState(true);

	const [locationError, setLocationError] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!location) return;

		const {city, houseNumber, lat, long, postal, streetName, state} = location;

		if (city && houseNumber && lat && long && postal && streetName && state) {
			setLocationError(false);
			setButtonDisabled(false);
		} else {
			setLocationError(true);
			setButtonDisabled(true);
		}
	}, [location]);

	function saveEntry() {
		setIsLoading(true);
		dispatch(
			setAddressInformation({
				streetName: location.streetName,
				houseNumber: location.houseNumber,
				city: location.city,
				long: location.long,
				lat: location.lat,
				postal: location.postal,
				state: location.state,
			})
		);
		setIsLoading(false);
		navigation.push('phoneNumberForm');
	}

	return (
		<View
			style={[
				tw.style('w-full h-full flex justify-between items-center px-6'),
				{zIndex: 1},
			]}
		>
			<View style={[tw.style('w-full flex-1'), {zIndex: 99}]}>
				<Text
					style={tw.style('text-xl font-bold text-gray-700 text-center my-7')}
				>
					Bitte gib deine Adresse ein.
				</Text>

				<GooglePlacesAutocomplete
					requestUrl={{
						useOnPlatform: 'web', // or "all"
						url: 'https://maps.googleapis.com/maps/api', // or any proxy server that hits https://maps.googleapis.com/maps/api
						headers: {
							Authorization: `an auth token`, // if required for your proxy
						},
					}}
					styles={{
						'textInput': tw.style(''),
						'textInputContainer': tw.style(
							'bg-white rounded-xl shadow-md py-4 px-5 relative'
						),
						'listView': tw.style('mt-1 rounded-xl mb-2 absolute top-13'),
						'row': tw.style('bg-white p-3 px-2 '),
						'separator': tw.style('bg-gray-300 h-.1'),
						'container': {zIndex: 999},
					}}
					textInputProps={{
						placeholderTextColor: tw.color('black'),
						returnKeyType: 'search',
					}}
					placeholder="Adresse"
					nearbyPlacesAPI="GooglePlacesSearch"
					debounce={400}
					enablePoweredByContainer={false}
					query={{
						key: process.env.GOOGLE_API_KEY,
						language: 'de',
						components: 'country:de',
					}}
					minLength={2}
					disableScroll={false}
					isRowScrollable={true}
					fetchDetails={true}
					returnKeyType="Suchen"
					suppressDefaultStyles
					onPress={(data, details = null) => {
						var houseNumber = 0;
						var streetName = '';
						var postal = 0;
						var city = '';
						var state = '';

						details.address_components.map(({types, long_name}) => {
							switch (types[0]) {
								case 'street_number':
									houseNumber = long_name;
									break;

								case 'route':
									streetName = long_name;
									break;

								case 'administrative_area_level_1':
									state = long_name;
									break;

								case 'postal_code':
									postal = long_name;
									break;

								case 'locality':
									city = long_name;
									break;

								default:
									break;
							}
						});

						setLocation({
							streetName: streetName,
							houseNumber: houseNumber,
							postal: postal,
							city: city,
							lat: details.geometry.location.lat,
							long: details.geometry.location.lng,
							state: state,
							placeID: uuid.v4(),
						});
					}}
				/>
				<Text
					style={[
						tw.style(
							'mt-2 text-sm ' +
								(locationError
									? 'text-red-500 font-bold'
									: 'text-gray-900 font-light')
						),
						{zIndex: 1},
					]}
				>
					Um eine Adresse zu nutzen, wählen einen der Vorschläge aus. Bitte
					Adresse mit Straße, Hausnummer und Ort eintragen
				</Text>
			</View>

			<View
				style={[
					tw.style('flex flex-row justify-center w-full mb-6'),
					{zIndex: 1},
				]}
			>
				<BackButton onPress={() => navigation.goBack()} />
				<NextButton
					onPress={() => saveEntry()}
					label="Weiter"
					showIcon
					disabled={buttonDisabled}
					loading={isLoading}
				/>
			</View>
			{Platform.OS === 'ios' && <KeyboardSpacer />}
		</View>
	);
}
