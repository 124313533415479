import React from 'react';
import {View, Text, Image, TouchableOpacity} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import tw from '../../lib/tailwind';
import LogoWOSubline from '../../assets/images/logo/no_subline.png';
import Illustration2 from '../../assets/images/illustrations/u-bring-delivery-absender_2.png';
import {Icon} from 'react-native-elements';
import * as SecureStore from 'expo-secure-store';
import {MaterialIcons} from '@expo/vector-icons';

export default function Stage2FirstOpen({navigation}) {
	async function goToStart() {
		try {
			await SecureStore.setItemAsync('ubring_first_open', JSON.stringify(true));
			navigation.replace('start');
		} catch (e) {
			console.log(e);
		}
	}

	return (
		<SafeAreaView
			edges={['left', 'top', 'right']}
			style={tw.style('w-full h-full bg-white')}
		>
			<View
				style={tw.style(
					'flex justify-center items-center w-full h-9/10 bg-white'
				)}
			>
				<Image
					style={tw.style('h-1/10')}
					width={'50%'}
					resizeMode="contain"
					source={LogoWOSubline}
				/>
				<Image
					style={tw.style('m-10 w-8/10 h-4/10 ')}
					height={'40%'}
					width={'80%'}
					resizeMode="contain"
					source={Illustration2}
				/>
				<View style={tw.style('w-4/5')}>
					<Text
						style={tw.style(
							'text-3xl font-bold text-gray-700 mb-5 text-center'
						)}
					>
						Werde Sender
					</Text>
					<Text style={tw.style('text-center')}>
						und profitiere als Ubi von günstigen Versandkosten bei der ersten
						nachhaltigen Transport Community in Deutschland
					</Text>
				</View>
			</View>
			<View
				style={tw.style('flex flex-row justify-between w-full h-1/10 px-10')}
			>
				<TouchableOpacity
					style={tw.style('w-1/3 h-10 flex justify-center')}
					onPress={() => goToStart()}
				>
					<Text style={tw.style('text-lg text-gray-600')}>Skip</Text>
				</TouchableOpacity>
				<View
					style={tw.style(
						'w-1/3 h-10 flex flex-row justify-evenly items-center'
					)}
				>
					<View style={tw.style('bg-gray-200 h-3 w-3 rounded-full')} />
					<View style={tw.style('bg-primary h-3 w-3 rounded-full')} />
					<View style={tw.style('bg-gray-200 h-3 w-3 rounded-full')} />
				</View>
				<TouchableOpacity
					style={tw.style('w-1/3 h-10 flex flex-row justify-end items-center')}
					onPress={() => navigation.navigate('stage3')}
				>
					<MaterialIcons
						name="arrow-forward"
						size={28}
						color={tw.color('primary')}
					/>
				</TouchableOpacity>
			</View>
		</SafeAreaView>
	);
}
