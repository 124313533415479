import React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function PackageProgressIndicator({ progress }) {
    const Progress0 = () => {
        return (
            <Svg
                width="40"
                height="56"
                viewBox="0 0 40 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <Path
                    d="M20.5494 34.8068L33.5999 27.272L28.6832 18.7562C27.9888 17.5536 26.8452 16.6761 25.5038 16.3166C24.1625 15.9572 22.7333 16.1453 21.5306 16.8396L13.0148 21.7563L20.5494 34.8068Z"
                    fill="#E6E6E6"
                />
                <Path
                    d="M19.204 35.5837L11.6692 22.5332L3.15347 27.4499C2.55796 27.7937 2.03601 28.2514 1.6174 28.7969C1.1988 29.3424 0.891743 29.965 0.713767 30.6292C0.535792 31.2934 0.490382 31.9861 0.580132 32.6679C0.669883 33.3496 0.893034 34.007 1.23685 34.6025L6.15355 43.1182L19.204 35.5837Z"
                    fill="#E6E6E6"
                />
                <Path
                    d="M19.9806 36.929L6.93036 44.4638L11.8471 52.9795C12.5414 54.1821 13.6851 55.0597 15.0264 55.4191C16.3678 55.7786 17.797 55.5904 18.9996 54.8962L27.5154 49.9794L19.9806 36.929Z"
                    fill="#E6E6E6"
                />
                <Path
                    d="M21.3262 36.1522L28.8607 49.2027L37.3767 44.286C37.9722 43.9422 38.4942 43.4845 38.9127 42.939C39.3313 42.3934 39.6383 41.7708 39.8163 41.1066C39.9942 40.4425 40.0396 39.7497 39.9499 39.068C39.8601 38.3863 39.6369 37.7289 39.2931 37.1335L34.3774 28.6174L21.3262 36.1522Z"
                    fill="#E6E6E6"
                />
                <Path
                    d="M10.3929 18.3758C10.727 18.9543 11.1053 19.506 11.5245 20.026C12.148 19.7857 12.7519 19.4974 13.3307 19.1636C14.7889 18.3219 16.0669 17.2011 17.0918 15.8654C18.1168 14.5297 18.8686 13.0051 19.3044 11.3788C19.7401 9.75252 19.8513 8.05632 19.6314 6.38707C19.4116 4.71783 18.8652 3.10822 18.0233 1.65017C17.689 1.07173 17.3105 0.520028 16.8911 0C15.1629 0.665934 13.5993 1.69829 12.3081 3.02607C11.0168 4.35384 10.0285 5.94554 9.41096 7.6917C8.79347 9.43785 8.5615 11.297 8.73101 13.1414C8.90052 14.9857 9.46748 16.7715 10.3929 18.3758V18.3758Z"
                    fill="#038C5A"
                />
                <Path
                    d="M9.50054 19.8717C10.1459 20.0446 10.8036 20.167 11.4679 20.2381C11.7384 19.6273 11.9611 18.9964 12.134 18.3512C13.014 15.0671 12.5535 11.5678 10.8537 8.62326C9.15388 5.67867 6.35401 3.52988 3.06996 2.64955C2.42447 2.47678 1.76654 2.35437 1.1021 2.28345C0.350974 3.97636 -0.0245846 5.81187 0.00124773 7.66376C0.0270801 9.51564 0.45369 11.34 1.25174 13.0113C2.04979 14.6826 3.20035 16.1612 4.6243 17.3455C6.04824 18.5298 7.71179 19.3916 9.50054 19.8717V19.8717Z"
                    fill="#41D992"
                />
            </Svg>
        );
    };

    const Progress25 = () => {
        return (
            <Svg
                width="40"
                height="56"
                viewBox="0 0 40 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <Path
                    d="M20.5494 34.8068L33.5999 27.272L28.6832 18.7562C27.9888 17.5536 26.8452 16.6761 25.5038 16.3166C24.1625 15.9572 22.7333 16.1453 21.5306 16.8396L13.0148 21.7563L20.5494 34.8068Z"
                    fill="#E6E6E6"
                />
                <Path
                    d="M19.204 35.5837L11.6692 22.5332L3.15347 27.4499C2.55796 27.7937 2.03601 28.2514 1.6174 28.7969C1.1988 29.3424 0.891743 29.965 0.713767 30.6292C0.535792 31.2934 0.490382 31.9861 0.580132 32.6679C0.669883 33.3496 0.893034 34.007 1.23685 34.6025L6.15355 43.1182L19.204 35.5837Z"
                    fill="#FFB169"
                />
                <Path
                    d="M19.9806 36.929L6.93036 44.4638L11.8471 52.9795C12.5414 54.1821 13.6851 55.0597 15.0264 55.4191C16.3678 55.7786 17.797 55.5904 18.9996 54.8962L27.5154 49.9794L19.9806 36.929Z"
                    fill="#E6E6E6"
                />
                <Path
                    d="M21.3262 36.1522L28.8607 49.2027L37.3767 44.286C37.9722 43.9422 38.4942 43.4845 38.9127 42.939C39.3313 42.3934 39.6383 41.7708 39.8163 41.1066C39.9942 40.4425 40.0396 39.7497 39.9499 39.068C39.8601 38.3863 39.6369 37.7289 39.2931 37.1335L34.3774 28.6174L21.3262 36.1522Z"
                    fill="#E6E6E6"
                />
                <Path
                    d="M10.3929 18.3758C10.727 18.9543 11.1053 19.506 11.5245 20.026C12.148 19.7857 12.7519 19.4974 13.3307 19.1636C14.7889 18.3219 16.0669 17.2011 17.0918 15.8654C18.1168 14.5297 18.8686 13.0051 19.3044 11.3788C19.7401 9.75252 19.8513 8.05632 19.6314 6.38707C19.4116 4.71783 18.8652 3.10822 18.0233 1.65017C17.689 1.07173 17.3105 0.520028 16.8911 0C15.1629 0.665934 13.5993 1.69829 12.3081 3.02607C11.0168 4.35384 10.0285 5.94554 9.41096 7.6917C8.79347 9.43785 8.5615 11.297 8.73101 13.1414C8.90052 14.9857 9.46748 16.7715 10.3929 18.3758V18.3758Z"
                    fill="#038C5A"
                />
                <Path
                    d="M9.50054 19.8717C10.1459 20.0446 10.8036 20.167 11.4679 20.2381C11.7384 19.6273 11.9611 18.9964 12.134 18.3512C13.014 15.0671 12.5535 11.5678 10.8537 8.62326C9.15388 5.67867 6.35401 3.52988 3.06996 2.64955C2.42447 2.47678 1.76654 2.35437 1.1021 2.28345C0.350974 3.97636 -0.0245846 5.81187 0.00124773 7.66376C0.0270801 9.51564 0.45369 11.34 1.25174 13.0113C2.04979 14.6826 3.20035 16.1612 4.6243 17.3455C6.04824 18.5298 7.71179 19.3916 9.50054 19.8717V19.8717Z"
                    fill="#41D992"
                />
            </Svg>
        );
    };

    const Progress50 = () => {
        return (
            <Svg
                width="40"
                height="56"
                viewBox="0 0 40 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <Path
                    d="M20.5494 34.8068L33.5999 27.272L28.6832 18.7562C27.9888 17.5536 26.8452 16.6761 25.5038 16.3166C24.1625 15.9572 22.7333 16.1453 21.5306 16.8396L13.0148 21.7563L20.5494 34.8068Z"
                    fill="#E6E6E6"
                />
                <Path
                    d="M19.204 35.5837L11.6692 22.5332L3.15347 27.4499C2.55796 27.7937 2.03601 28.2514 1.6174 28.7969C1.1988 29.3424 0.891743 29.965 0.713767 30.6292C0.535792 31.2934 0.490382 31.9861 0.580132 32.6679C0.669883 33.3496 0.893034 34.007 1.23685 34.6025L6.15355 43.1182L19.204 35.5837Z"
                    fill="#FFB169"
                />
                <Path
                    d="M19.9806 36.929L6.93036 44.4638L11.8471 52.9795C12.5414 54.1821 13.6851 55.0597 15.0264 55.4191C16.3678 55.7786 17.797 55.5904 18.9996 54.8962L27.5154 49.9794L19.9806 36.929Z"
                    fill="#FFB169"
                />
                <Path
                    d="M21.3262 36.1522L28.8607 49.2027L37.3767 44.286C37.9722 43.9422 38.4942 43.4845 38.9127 42.939C39.3313 42.3934 39.6383 41.7708 39.8163 41.1066C39.9942 40.4425 40.0396 39.7497 39.9499 39.068C39.8601 38.3863 39.6369 37.7289 39.2931 37.1335L34.3774 28.6174L21.3262 36.1522Z"
                    fill="#E6E6E6"
                />
                <Path
                    d="M10.3929 18.3758C10.727 18.9543 11.1053 19.506 11.5245 20.026C12.148 19.7857 12.7519 19.4974 13.3307 19.1636C14.7889 18.3219 16.0669 17.2011 17.0918 15.8654C18.1168 14.5297 18.8686 13.0051 19.3044 11.3788C19.7401 9.75252 19.8513 8.05632 19.6314 6.38707C19.4116 4.71783 18.8652 3.10822 18.0233 1.65017C17.689 1.07173 17.3105 0.520028 16.8911 0C15.1629 0.665934 13.5993 1.69829 12.3081 3.02607C11.0168 4.35384 10.0285 5.94554 9.41096 7.6917C8.79347 9.43785 8.5615 11.297 8.73101 13.1414C8.90052 14.9857 9.46748 16.7715 10.3929 18.3758V18.3758Z"
                    fill="#038C5A"
                />
                <Path
                    d="M9.50054 19.8717C10.1459 20.0446 10.8036 20.167 11.4679 20.2381C11.7384 19.6273 11.9611 18.9964 12.134 18.3512C13.014 15.0671 12.5535 11.5678 10.8537 8.62326C9.15388 5.67867 6.35401 3.52988 3.06996 2.64955C2.42447 2.47678 1.76654 2.35437 1.1021 2.28345C0.350974 3.97636 -0.0245846 5.81187 0.00124773 7.66376C0.0270801 9.51564 0.45369 11.34 1.25174 13.0113C2.04979 14.6826 3.20035 16.1612 4.6243 17.3455C6.04824 18.5298 7.71179 19.3916 9.50054 19.8717V19.8717Z"
                    fill="#41D992"
                />
            </Svg>
        );
    };

    const Progress75 = () => {
        return (
            <Svg
                width="40"
                height="56"
                viewBox="0 0 40 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <Path
                    d="M20.5494 34.8068L33.5999 27.272L28.6832 18.7562C27.9888 17.5536 26.8452 16.6761 25.5038 16.3166C24.1625 15.9572 22.7333 16.1453 21.5306 16.8396L13.0148 21.7563L20.5494 34.8068Z"
                    fill="#E6E6E6"
                />
                <Path
                    d="M19.204 35.5837L11.6692 22.5332L3.15347 27.4499C2.55796 27.7937 2.03601 28.2514 1.6174 28.7969C1.1988 29.3424 0.891743 29.965 0.713767 30.6292C0.535792 31.2934 0.490382 31.9861 0.580132 32.6679C0.669883 33.3496 0.893034 34.007 1.23685 34.6025L6.15355 43.1182L19.204 35.5837Z"
                    fill="#FFB169"
                />
                <Path
                    d="M19.9806 36.929L6.93036 44.4638L11.8471 52.9795C12.5414 54.1821 13.6851 55.0597 15.0264 55.4191C16.3678 55.7786 17.797 55.5904 18.9996 54.8962L27.5154 49.9794L19.9806 36.929Z"
                    fill="#FFB169"
                />
                <Path
                    d="M21.3262 36.1522L28.8607 49.2027L37.3767 44.286C37.9722 43.9422 38.4942 43.4845 38.9127 42.939C39.3313 42.3934 39.6383 41.7708 39.8163 41.1066C39.9942 40.4425 40.0396 39.7497 39.9499 39.068C39.8601 38.3863 39.6369 37.7289 39.2931 37.1335L34.3774 28.6174L21.3262 36.1522Z"
                    fill="#FFB169"
                />
                <Path
                    d="M10.3929 18.3758C10.727 18.9543 11.1053 19.506 11.5245 20.026C12.148 19.7857 12.7519 19.4974 13.3307 19.1636C14.7888 18.3219 16.0669 17.2011 17.0918 15.8654C18.1168 14.5297 18.8686 13.0051 19.3044 11.3788C19.7401 9.75252 19.8513 8.05632 19.6314 6.38707C19.4116 4.71783 18.8652 3.10822 18.0233 1.65017C17.689 1.07173 17.3105 0.520028 16.8911 0C15.1629 0.665934 13.5993 1.69829 12.3081 3.02607C11.0168 4.35384 10.0285 5.94554 9.41096 7.6917C8.79347 9.43785 8.5615 11.297 8.73101 13.1414C8.90052 14.9857 9.46748 16.7715 10.3929 18.3758V18.3758Z"
                    fill="#038C5A"
                />
                <Path
                    d="M9.50053 19.8717C10.1459 20.0446 10.8036 20.167 11.4679 20.2381C11.7383 19.6273 11.9611 18.9964 12.134 18.3512C13.014 15.0671 12.5535 11.5678 10.8537 8.62326C9.15388 5.67867 6.35401 3.52988 3.06996 2.64955C2.42447 2.47678 1.76654 2.35437 1.1021 2.28345C0.350974 3.97636 -0.0245846 5.81187 0.00124773 7.66376C0.0270801 9.51564 0.45369 11.34 1.25174 13.0113C2.04979 14.6826 3.20035 16.1612 4.62429 17.3455C6.04824 18.5298 7.71178 19.3916 9.50053 19.8717V19.8717Z"
                    fill="#41D992"
                />
            </Svg>
        );
    };

    const Progress100 = () => {
        return (
            <Svg
                width="40"
                height="56"
                viewBox="0 0 40 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <Path
                    d="M20.5494 34.8068L33.5999 27.272L28.6832 18.7562C27.9888 17.5536 26.8452 16.6761 25.5038 16.3166C24.1625 15.9572 22.7333 16.1453 21.5306 16.8396L13.0148 21.7563L20.5494 34.8068Z"
                    fill="#FFB169"
                />
                <Path
                    d="M19.204 35.5837L11.6692 22.5332L3.15347 27.4499C2.55796 27.7937 2.03601 28.2514 1.6174 28.7969C1.1988 29.3424 0.891743 29.965 0.713767 30.6292C0.535792 31.2934 0.490382 31.9861 0.580132 32.6679C0.669883 33.3496 0.893034 34.007 1.23685 34.6025L6.15355 43.1182L19.204 35.5837Z"
                    fill="#FFB169"
                />
                <Path
                    d="M19.9806 36.929L6.93036 44.4637L11.8471 52.9795C12.5414 54.1821 13.6851 55.0597 15.0264 55.4191C16.3678 55.7786 17.797 55.5904 18.9996 54.8961L27.5154 49.9794L19.9806 36.929Z"
                    fill="#FFB169"
                />
                <Path
                    d="M21.3262 36.1522L28.8607 49.2027L37.3767 44.286C37.9722 43.9422 38.4942 43.4845 38.9127 42.939C39.3313 42.3934 39.6383 41.7708 39.8163 41.1066C39.9942 40.4425 40.0396 39.7497 39.9499 39.068C39.8601 38.3863 39.6369 37.7289 39.2931 37.1335L34.3774 28.6174L21.3262 36.1522Z"
                    fill="#FFB169"
                />
                <Path
                    d="M10.3929 18.3758C10.727 18.9543 11.1053 19.506 11.5245 20.026C12.148 19.7857 12.7519 19.4974 13.3307 19.1636C14.7888 18.3219 16.0669 17.2011 17.0918 15.8654C18.1168 14.5297 18.8686 13.0051 19.3044 11.3788C19.7401 9.75253 19.8513 8.05633 19.6314 6.38708C19.4116 4.71783 18.8652 3.10822 18.0233 1.65017C17.689 1.07173 17.3105 0.520029 16.8911 0C15.1629 0.665934 13.5993 1.69829 12.3081 3.02607C11.0168 4.35384 10.0285 5.94554 9.41096 7.6917C8.79347 9.43785 8.5615 11.297 8.73101 13.1414C8.90052 14.9857 9.46748 16.7715 10.3929 18.3758V18.3758Z"
                    fill="#038C5A"
                />
                <Path
                    d="M9.50053 19.8717C10.1459 20.0446 10.8036 20.167 11.4679 20.2381C11.7383 19.6273 11.9611 18.9964 12.134 18.3512C13.014 15.0671 12.5535 11.5678 10.8537 8.62326C9.15388 5.67867 6.35401 3.52988 3.06996 2.64955C2.42447 2.47678 1.76654 2.35437 1.1021 2.28345C0.350974 3.97636 -0.0245846 5.81187 0.00124773 7.66376C0.0270801 9.51564 0.45369 11.34 1.25174 13.0113C2.04979 14.6826 3.20035 16.1612 4.62429 17.3455C6.04824 18.5298 7.71178 19.3916 9.50053 19.8717V19.8717Z"
                    fill="#41D992"
                />
            </Svg>
        );
    };

    const ProgressIndicator = () => {
        switch (progress) {
            case 0:
                return <Progress0 />;

            case 1:
                return <Progress25 />;

            case 2:
                return <Progress50 />;

            case 3:
                return <Progress75 />;

            case 4:
                return <Progress100 />;

            default:
                return <Progress0 />;
        }
    };

    return <ProgressIndicator />;
}
