import {
    View,
    Text,
    Keyboard,
    TouchableWithoutFeedback,
    Platform,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import UBring from './main';
import tw from '../../../lib/tailwind';
import HeaderBar from '../../../uiKit/headerBar';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../../slices/userSlice';
import AddRoute from './addRoute';
import SetDestination from './setDestination';
import SetOrigin from './setOrigin';
import ShowPackages from './showPackages';
import PackageInfo from './packageInfo';
import RequestConfimation from './requestConfimation';

export default function UBringIndex({ navigation }) {
    const Stack = createNativeStackNavigator();

    const userInfo = useSelector(selectUserInfo);

    useEffect(() => {
        if (!userInfo.extended) {
            navigation.replace('loginContainer', { screen: 'registerUbring' });
        }
    }, [userInfo]);

    return (
        <TouchableWithoutFeedback
            onPress={() => {
                if (Platform.OS != 'web') {
                    Keyboard.dismiss();
                }
            }}
        >
            <View style={tw.style('flex-1')}>
                <Stack.Navigator
                    screenOptions={{ headerShown: false }}
                    initialRouteName="addRoute"
                >
                    <Stack.Screen name="route" component={UBring} />
                    <Stack.Screen name="addRoute" component={AddRoute} />
                    <Stack.Screen
                        name="setDestination"
                        component={SetDestination}
                    />
                    <Stack.Screen name="setOrigin" component={SetOrigin} />
                    <Stack.Screen
                        name="showPackages"
                        component={ShowPackages}
                    />
                    <Stack.Screen name="packageInfo" component={PackageInfo} />
                    <Stack.Screen
                        name="requestConfirmation"
                        component={RequestConfimation}
                    />
                </Stack.Navigator>
            </View>
        </TouchableWithoutFeedback>
    );
}
