import React from 'react';
import { View, Text, TouchableOpacity, Button } from 'react-native';
import tw from '../lib/tailwind';

export default function StartMenuButton({ title, color, onPress }) {
    var borderColor = 'white';
    var fontColor = 'white';

    switch (color) {
        case 'gray':
            borderColor = 'gray-300';
            fontColor = 'gray-700';
            break;

        case 'yellow':
            borderColor = 'accent';
            fontColor = 'accent';
            break;

        case 'primary':
            borderColor = 'primary';
            fontColor = 'primary';
            break;

        default:
            break;
    }

    return (
        <TouchableOpacity onPress={onPress}>
            <View
                style={tw.style(
                    'w-full py-3 rounded-xl border-4 border-' +
                        borderColor +
                        ' bg-white flex items-center justify-center mt-7'
                )}
            >
                <Text style={tw.style('font-bold text-lg text-' + fontColor)}>
                    {title}
                </Text>
            </View>
        </TouchableOpacity>
    );
}
