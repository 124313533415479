import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {
	View,
	Text,
	Image,
	Platform,
	TouchableOpacity,
	Dimensions,
} from 'react-native';
import {StatusBar} from 'expo-status-bar';
import tw from '../lib/tailwind';
import Logo from '../assets/images/logo/icon-round-white.png';
import {useDispatch, useSelector} from 'react-redux';
import {setHeaderbarLayout} from '../slices/headerBarSlice';
import {Avatar} from 'react-native-elements';
import {selectLoggedInState, selectUserInfo} from '../slices/userSlice';
import {useNavigation} from '@react-navigation/native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Ionicons, MaterialIcons} from '@expo/vector-icons';

export default function HeaderBar({siteTitle}) {
	const dispatch = useDispatch();

	const navigation = useNavigation();

	const userInfo = useSelector(selectUserInfo);
	const isLoggedIn = useSelector(selectLoggedInState);

	const [headerTitleHeight, setHeaderTitleHeight] = useState(0);

	return (
		<View
			style={tw.style(
				'w-full bg-primary shadow-lg h-[' +
					36 +
					'] z-10 pb-3 ' +
					(Platform.OS === 'web' ? 'relative top-0 left-0 right-0' : 'pt-3')
			)}
		>
			<SafeAreaView
				edges={['top']}
				style={tw.style('')}
				onLayout={(event) =>
					dispatch(setHeaderbarLayout(event.nativeEvent.layout))
				}
			></SafeAreaView>
			<View
				style={tw.style(
					'w-full flex-1 px-2 flex flex-row justify-between items-center'
				)}
			>
				<View style={tw.style('w-1/6 px-2')}>
					<Image
						source={Logo}
						style={tw.style('w-full max-h-full')}
						resizeMode="contain"
					/>
				</View>
				<View
					style={tw.style('w-4/6 flex flex-row justify-center items-center')}
				>
					<Text
						style={tw.style('text-3xl font-bold text-white overflow-visible')}
						onLayout={(event) => {
							let height = event.nativeEvent.layout.height;

							if (height < headerTitleHeight) {
								return;
							}

							console.log(height);

							setHeaderTitleHeight(height);
						}}
					>
						{siteTitle}
					</Text>
				</View>
				<View style={tw.style('w-1/6 flex flex-row justify-end px-2')}>
					<TouchableOpacity
						style={tw.style('')}
						onPress={() =>
							isLoggedIn
								? navigation.navigate('authenticatedContainer', {
										screen: 'uAndMe',
								  })
								: navigation.navigate('loginContainer', {
										screen: 'login',
										params: {
											screen: 'start',
										},
								  })
						}
					>
						{isLoggedIn ? (
							<Avatar
								rounded
								size={38}
								containerStyle={tw.style('bg-gray-200')}
								source={
									isLoggedIn && userInfo?.email
										? {
												uri:
													userInfo.avatarSrc !== ''
														? userInfo.avatarSrc
														: 'https://api.multiavatar.com/' +
														  userInfo.email +
														  '.png',
										  }
										: {}
								}
							/>
						) : (
							<Ionicons
								name="person"
								color={tw.color('gray-400')}
								size={24}
								style={tw.style('bg-gray-200 rounded-full p-2')}
							/>
						)}
					</TouchableOpacity>
				</View>
			</View>
		</View>
	);
}
