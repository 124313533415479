import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    headerBarLayout: {
        height: 0,
        width: 0,
    },
};

export const headerBarSlice = createSlice({
    name: 'headerBar',
    initialState,
    reducers: {
        setHeaderbarLayout: (state, action) => {
            state.headerBarLayout.height = action.payload.height;
            state.headerBarLayout.width = action.payload.width;
        },
    },
});

export const { setHeaderbarLayout } = headerBarSlice.actions;

export const selectHeaderBarLayout = (state) => state.headerBar.headerBarLayout;

export default headerBarSlice.reducer;
