import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {
	View,
	Text,
	Platform,
	Dimensions,
	KeyboardAvoidingView,
	ScrollView,
	TextInput,
	Alert,
	TouchableOpacity,
} from 'react-native';
import {CheckBox, Icon} from 'react-native-elements';
import {useDispatch, useSelector} from 'react-redux';
import tw from '../../../lib/tailwind';
import {
	selectRegisterUserInfo,
	setUserID,
} from '../../../slices/registerUserSlice';
import BackButton from '../../../uiKit/backButton';
import Input from '../../../uiKit/input';
import NextButton from '../../../uiKit/nextButton';
import {v4 as uuidv4} from 'uuid';
import {selectHeaderBarLayout} from '../../../slices/headerBarSlice';
import KeyboardSpacer from 'react-native-keyboard-spacer';
import * as WebBrowser from 'expo-web-browser';
import {MaterialIcons} from '@expo/vector-icons';

export default function LegalForm({navigation}) {
	const [disableButton, setDisableButton] = useState(true);
	const [checkLegal, setCheckLegal] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const newUserInfo = useSelector(selectRegisterUserInfo);
	const HeaderBarLayout = useSelector(selectHeaderBarLayout);

	const dispatch = useDispatch();
	console.log(newUserInfo);

	useEffect(() => {
		if (checkLegal) {
			setDisableButton(false);
		} else {
			setDisableButton(true);
		}
	}, [checkLegal]);

	async function registerUser() {
		setIsLoading(true);

		try {
			let res = await axios.post(process.env.APIURL + 'register', {
				'username': newUserInfo.username,
				'firstname': newUserInfo.firstname,
				'lastname': newUserInfo.lastname,
				'companyname': newUserInfo.isCompany ? newUserInfo.companyName : '',
				'address': {
					'city': newUserInfo.addressInformation.city,
					'state': newUserInfo.addressInformation.state,
					'latitude': newUserInfo.addressInformation.lat,
					'longitude': newUserInfo.addressInformation.long,
					'shortString':
						newUserInfo.addressInformation.streetName +
						' ' +
						newUserInfo.addressInformation.houseNumber,
					'longString':
						newUserInfo.addressInformation.streetName +
						' ' +
						newUserInfo.addressInformation.houseNumber +
						', ' +
						newUserInfo.addressInformation.postal +
						' ' +
						newUserInfo.addressInformation.city +
						', Deutschland',
					'number': newUserInfo.addressInformation.houseNumber,
					'street': newUserInfo.addressInformation.streetName,
					'postal': newUserInfo.addressInformation.postal,
					'placeID': uuidv4(),
				},
				'email': newUserInfo.email,
				'telephone': newUserInfo.phoneNumber,
				'password': newUserInfo.password,
				'legal': true,
			});

			if (res.data) {
				console.log(res.data);
				dispatch(setUserID(res.data.data.id));
				setIsLoading(false);
				navigation.reset({
					index: 0,
					routes: [{name: 'validateCode'}],
				});
			}
		} catch (error) {
			console.log(error.response);
			Alert.alert(
				'Fehler',
				'Beim registrieren ist ein Fehler aufgetreten. Die E-Mail Adresse wurde bereits registriert.'
			);
			setIsLoading(false);
		}
	}

	const titleStyle = tw.style('text-lg font-semibold mb-4');
	const paragraphStyle = tw.style('mb-3');

	return (
		<View
			style={tw.style(
				'flex-1 w-full h-full flex justify-between items-center px-6'
			)}
		>
			<View style={tw.style('w-full')}>
				<Text
					style={tw.style('text-xl font-bold text-gray-700 text-center my-7')}
				>
					Zustimmung AGB und Einwilligung zur Datenerfassung
				</Text>
			</View>
			<ScrollView bounces={false} style={tw.style('flex-1 w-full')}>
				<View style={tw.style('flex-1')}>
					<Text style={titleStyle} onPress={() => {}}>
						1) Geltungsbereich
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						1.1 Diese Allgemeinen Geschäftsbedingungen (nachfolgend "AGB") der
						Kröber Lutz, Bastian Markus GbR – „U-BRING Eco-Logistics“
						(nachfolgend "Vermittler"), gelten für alle Verträge über den
						privaten Transport von Waren, die Kunde A (nachfolgend „Sender“) mit
						Kunde B (nachfolgend „Bringer“) über die U-BRING APP abschließt.
						Hiermit wird der Einbeziehung von eigenen Bedingungen des Kunden
						widersprochen, es sei denn, es ist etwas anderes vereinbart.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						1.2 Verbraucher im Sinne dieser AGB ist jede natürliche Person, die
						ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder
						ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit
						zugerechnet werden können. Unternehmer im Sinne dieser AGB ist eine
						natürliche oder juristische Person oder eine rechtsfähige
						Personengesellschaft, die bei Abschlusseines Rechtsgeschäfts in
						Ausübung ihrer gewerblichen oder selbständigen beruflichen
						Tätigkeithandelt.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						1.3 Das Angebot des Vermittlers richtet sich nur an Verbraucher und
						Unternehmer als Endverbraucher.
					</Text>
					<Text style={titleStyle} onPress={() => {}}>
						2) Datenerfassung
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						2.1 Die Nutzung der Dienstleistung des Vermittlers erfordert die
						Anlage eines Nutzerkontos für alle Teilnehmer (Sender, Bringer und
						Empfänger) um die Vernetzung aller Teilnehmer sicher zu stellen.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						2.2 Für die Anlage eines Nutzerkontos ist die Erfassung
						personenbezogener Daten erforderlich. Diese werden bei der
						erstmaligen Anmeldung erhoben. Der Vermittler bürgt für die sichere
						Handhabung der Daten nach Datenschutz-Grundverordnung (DSGVO). Des
						Weiteren sichert der Vermittler allen Teilnehmern zu, die Daten
						keinen Dritten außerhalb der Teilnehmer der
						Vermittlungsdienstleistung zugänglich zu machen.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						2.3 Für die Auftragsverfolgung ist die Aktivierung der
						Lokalisierungsfunktion auf GPS-Basis des Mobiltelefons des Bringers
						erforderlich für den Zeitraum der Erbringung der Dienstleistung. Die
						ausgetauschten Daten werden nicht gespeichert und die Funktion per
						APP automatisch nach Abschluss der Dienstleistung deaktiviert.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						2.4 Nähere Informationen zur Datenerfassung ergeben sich aus der
						Einwilligung zur Datenerfassung.
					</Text>
					<Text style={titleStyle} onPress={() => {}}>
						3) Vertragsschluss
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						3.1 Die folgenden Regelungen über den Vertragsschluss gelten für die
						Inanspruchnahme der online Dienstleistung des Vermittlers.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						3.2 Im Falle des Vertragsschlusses hinsichtlich Einstellen eines
						Transportauftrags kommt der Vermittlungsvertrag zwischen dem Sender
						und dem Vermittler(Schäfflerstraße 10, 85055 Ingolstadt, E-Mail:
						info@u-bring.de)zustande.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						3.3 Im Falle des Vertragsschlusses hinsichtlich Durchführung eines
						Transportauftrags kommt der Transportvertrag ausschließlich zwischen
						dem Sender und dem Bringer zustande
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						3.4 Die über die U-BRING APP des Vermittlers angebotenen
						Dienstleistungen stellen keine verbindlichen Angebote seitens des
						Vermittlers dar, sondern dienen zur Abgabe eines verbindlichen
						Angebots durch den Sender oder der verbindlichen Annahme eines
						Angebots durch den Bringer.
					</Text>
					<Text style={titleStyle} onPress={() => {}}>
						3.5 Transportaufträge
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						3.5.1 Einstellen eines Transportauftrags - Der Sender kann seinen
						Transportauftrag kostenfrei über die U-BRING APP inserieren.
						Unmittelbar nach Einstellen des Transportauftrags erhält der Sender
						vom Vermittler eine Einstellbestätigung, die jedoch noch keine
						Annahme des Vertragsangebots darstellt. Meldet sich innerhalb der
						Inseratsdauer ein Bringer und wird dieser von dem Sender bestätigt,
						kommt durch Klicken des den Einstellvorgang abschließenden Buttons
						„kostenpflichtig versenden“ ein rechtlich verbindlicher Vertrag in
						Bezug auf die ausgewählte Transport-Dienstleistung zustande. Die
						Dienstleistung kann jedoch nur abgegeben und übermittelt werden,
						wenn der Sender durch Klicken des Buttons „AGB akzeptieren“ und des
						Buttons „Widerrufsbelehrung akzeptieren“ diese Vertragsbedingungen
						akzeptiert hat.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						3.5.2 Anfrage zu einem Transportauftrag - Der Bringer kann sich über
						die U-BRING APP für einen verfügbaren Auftrag bewerben. Dabei gibt
						der Bringer, nachdem er den ausgewählten Transportauftrag durch
						Klicken des den Bestellvorgang abschließenden Buttons „Auftrag
						anfragen“ auswählt, ein rechtlich verbindliches Vertragsangebot in
						Bezug auf den ausgewählten Transportauftrag ab. Der Transportauftrag
						kann jedoch nur durchgeführt und die Kontaktdaten übermittelt
						werden, wenn der Bringer durch Klicken des Buttons „AGB akzeptieren“
						und des Buttons „Widerrufsbelehrung akzeptieren“ diese
						Vertragsbedingungen akzeptiert und dadurch in den Transportvertrag
						einbezogen wird.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						3.6 Bei Zustandekommen eines Transportvertrags über die U-BRING APP
						des Vermittlers wird der Vertragstext nach dem Vertragsschluss durch
						den Vermittler automatisiert generiert, gespeichert und sowohl dem
						Sender, als auch dem Bringer (nachfolgend „Parteien“) in Textform
						(E-Mail) einschließlich der Widerrufsbelehrung und mit Angaben der
						zu versendenden Ware, sowie der Transportprämie übermittelt. Eine
						darüberhinausgehende Zugänglichmachung des Vertragstextes durch den
						Vermittler erfolgt nicht. Die Teilnehmer haben jeder ein Nutzerkonto
						über Registrierung in der U-BRING APP des Vermittlers eingerichtet,
						über welches die Auftragsdaten durch den Vermittler archiviert
						werden. Über den passwortgeschützten Zugang, unter Angabe der
						entsprechenden Login-Daten, können die Unterlagen kostenlos
						abgerufen werden.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						3.7 Im Rahmen des Einstellverfahren legt der Sender über die U_BRING
						APP zunächst den gewünschten Transportauftrag an und veröffentlicht
						diesen In der U_BRING APP kann der Sender jederzeit die gewünschten
						Aufträge auswählen, einsehen oder stornieren.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						3.8 Im Rahmen des Vermittlungsverfahrens wählt der Bringer mit der
						U-BRING APP einen Transportauftrag aus. Die Auswahl erfolgt durch
						einen Klick auf ein Fähnchen auf der Landkartendarstellung (Points
						of interest - nachfolgend „POI“) der durch den Bringer angegebenen
						Route und öffnet eine Detailansicht des Transportauftrags. Durch
						einen Klick auf den „Auftrag anfragen“ Button wird der Sender über
						die Kontaktaufnahme des Bringers informiert und zur Bestätigung
						aufgefordert.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						3.9 Bestätigt der Sender die Anfrage des Bringers durch Klicken des
						Buttons „Bringer annehmen“ in der U_BRING APP, wird der Auftrag des
						Senders verbindlich iSd Ziffer 3.5 dieser AGB. Die Bestätigung des
						Bringers durch den Sender führt zu einem Transportvertrag zwischen
						Sender und Bringer. Dieser Transportvertrag wird automatisch als
						Dienstleistung über den Vermittler erstellt und beiden Parteien
						inklusive der unter iSd Ziffer 3.6 dieser AGB aufgeführten
						Einverständniserklärungen per E-Mail zugesandt.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						3.10 Bestätigt der Sender den Bringer nicht, kommt kein
						Transportvertrag zustande.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						3.11 Die Kontaktaufnahme und Transportabwicklung zwischen Sender und
						Bringer finden ohne Beteiligung des Vermittlers und in der Regel per
						Kurznachrichtendienst (z.B. Twitter, WhatsApp, etc.), E-Mail oder
						telefonisch statt. Die Parteien haben selbst sicherzustellen, dass
						die von ihnen zur Bestellabwicklung angegebenen E-Mail-Adressen
						zutreffend sind, so dass unter diesen Adressen die versandten
						E-Mails empfangen werden können. Insbesondere haben die Parteien bei
						dem Einsatz von SPAM-Filtern sicherzustellen, dass alle zur
						Bestellabwicklung versandten E-Mails zugestellt werden können.
					</Text>

					<Text style={titleStyle} onPress={() => {}}>
						4) Widerrufsrecht
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						4.1 Verbrauchern steht grundsätzlich ein Widerrufsrecht zu.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						4.2 Nähere Informationen zum Widerrufsrecht ergeben sich aus der
						Widerrufsbelehrung.
					</Text>
					<Text style={titleStyle} onPress={() => {}}>
						5) Preise und Zahlungsbedingungen
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						5.1 Sofern sich aus dem Transportauftrag des Senders nichts Anderes
						ergibt, handelt es sich bei den angegebenen Preisen um Gesamtpreise,
						die die gesetzliche Mehrwertsteuer enthalten. Die Mehrwertsteuer
						wird in allen Rechnungen separat ausgewiesen
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						5.2 Die Zahlungsabwicklung über die U-BRING APP erfolgt per
						Zahlungsdienstleister mollie für die Vermittlungsgebühr. Bei
						Zustandekommen eines Transportvertrages hat der Sender als
						Auftraggeber hier die Möglichkeit zwischen verschiedenen
						Zahlungsmöglichkeiten zu wählen.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						5.3 Die Rechnung über die Vermittlungsgebühr erhält der Sender nach
						Zustandekommen des Transportvertrages. Die Rechnung wird automatisch
						erstellt und dem Sender per E-Mail zugesandt. Darüber hinaus wird
						die Rechnung unter dem Nutzerkonto des Senders in der U-BRING APP
						des Vermittlers archiviert und kann über den Sender über sein
						passwortgeschütztes Nutzerkonto unter Angabe der entsprechenden
						Login-Daten kostenlos abgerufen werden.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						5.4 Nach erfolgreichem Abschluss des Transportes erhält der Sender
						per Kurznachricht die Aufforderung, die Transportprämie an den
						Bringer zu überweisen. Die Wahl der Zahlungsmöglichkeiten ist
						zwischen den Vertragspartnern Sender und Bringer zu vereinbaren.
					</Text>
					<Text style={titleStyle} onPress={() => {}}>
						6) Liefer-und Versandbedingungen
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						6.1 Die Lieferung von Waren erfolgt auf dem Versandweg, durch den
						per Transportvertrag vereinbarten Bringer, an die vom Sender
						angegebene Lieferanschrift, sofern nichts Anderes vereinbart ist.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						6.2 Ist eine Zustellung beim Empfänger nicht möglich, ist eine
						Alternative Abgabemöglichkeit (z.B. Abgabe beim Nachbarn) zwischen
						Partnern des Transportvertrages abzustimmen. Hierzu werden bei
						Abschluss des Transportvertrages die Kontaktdaten aller drei
						Parteien (Sender, Bringer und Empfänger) durch den Vermittler
						mitgeteilt.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						6.3 Die Ankunft des Bringers wird dem Empfänger zusätzlich per
						Push-Nachricht ca.10 Minuten vor Ankunft angekündigt. Hierzu ist die
						Aktivierung der Lokalisierungsfunktion auf GPS-Basis des
						Mobiltelefons des Bringers erforderlich für den Zeitraum der
						Erbringung der Dienstleistung. Der Bringer erklärt sich hierzu mit
						der Einwilligung zur Datenerfassung einverstanden.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						6.4 Erfolgt die Lieferung des Transportgutes nicht innerhalb des
						zwischen Sender und Bringer vereinbarten Zeitfensters und ist der
						Bringer auch über alle weiteren Kontaktmittel nicht erreichbar,
						stehen dem Sender die iSd Ziffer 10 dieser AGB Mittel zur
						Streitbeilegung zur Verfügung.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						6.5 Erfolgt die Zahlung der Transportprämie durch den Sender an den
						Bringer nicht innerhalb des zwischen Sender und Bringer vereinbarten
						Zeitfensters und ist der Sender auch über alle weiteren
						Kontaktmittel nicht erreichbar, stehen dem Bringer die iSd Ziffer 10
						dieser AGB Mittel zur Streitbeilegung zur Verfügung.
					</Text>
					<Text style={titleStyle} onPress={() => {}}>
						7) Haftungsbeschränkung
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						Ansprüche des Senders und des Bringers, sowie des Empfängers auf
						Schadensersatz beim Vermittler sind ausgeschlossen.
					</Text>
					<Text style={titleStyle} onPress={() => {}}>
						8) Anwendbares Recht
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						Für sämtliche Rechtsbeziehungen der Parteien gilt das Recht der
						Bundesrepublik Deutschland unter Ausschluss der Vorschriften des
						internationalen Privatrechts sowie des UN-Kaufrechts.
					</Text>
					<Text style={titleStyle} onPress={() => {}}>
						9) Gerichtsstand
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						9.1 Für den Sender als Auftraggeber und den Bringer als
						Auftragnehmer mit Sitz im Hoheitsgebiet der Bundesrepublik
						Deutschland, ist für alle Streitigkeiten aus diesem Vertrag
						ausschließlicher Gerichtsstand der Wohnsitz des Senders.
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						9.2 Die Kröber Lutz, Bastian Markus GbR – „U-BRING Eco-Logistics“
						ist ausschließlich Vermittler des Transportauftrags und ist zur
						Teilnahme an einem Streitbeilegungsverfahren vor einer
						Verbraucherschlichtungsstelle weder verpflichtet noch bereit.
					</Text>
					<Text style={titleStyle} onPress={() => {}}>
						10) Alternative Streitbeilegung
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						Die EU-Kommission stellt im Internet unter folgendem Link eine
						Plattform zur Online-Streitbeilegung bereit:
						https://ec.europa.eu/info/departments/justice-and-consumers_de Diese
						Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung
						von Streitigkeiten aus Online- Dienstleistungsverträgen, an denen
						ein Verbraucher beteiligt ist.
					</Text>

					<Text style={titleStyle} onPress={() => {}}>
						11) Datenschutz
					</Text>
					<Text style={paragraphStyle} onPress={() => {}}>
						<TouchableOpacity
							onPress={() => {
								WebBrowser.openBrowserAsync('https://u-bring.de/datenschutz/');
							}}
						>
							<Text style={[paragraphStyle, {textDecorationLine: 'underline'}]}>
								Hier geht es zur Datenschutzerklärung
							</Text>
						</TouchableOpacity>
					</Text>

					<CheckBox
						checked={checkLegal}
						onPress={() => setCheckLegal(!checkLegal)}
						containerStyle={tw.style(
							'bg-background shadow-none border-0 bg-opacity-0'
						)}
						checkedIcon={
							<MaterialIcons
								name="radio-button-checked"
								color={tw.color('primary')}
							/>
						}
						uncheckedIcon={
							<MaterialIcons
								name="radio-button-unchecked"
								color={tw.color('primary')}
							/>
						}
						title={
							'Ich habe die AGB gelesen und aktzeptiere die Verarbeitung meiner Daten nach Datenschutzerklärung.'
						}
					/>
				</View>
			</ScrollView>
			<View>
				<View style={tw.style('flex flex-row justify-center w-full mb-6')}>
					<BackButton onPress={() => navigation.goBack()} />
					<NextButton
						onPress={() => registerUser()}
						label="Registrieren"
						showIcon
						disabled={disableButton}
						loading={isLoading}
					/>
				</View>
				{Platform.OS === 'ios' && <KeyboardSpacer />}
			</View>
		</View>
	);
}
