import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loggedInState: false,
    token: null,
    userInformation: null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setToken: (state, action) => {
            if (action.payload) {
                state.token = action.payload;
                state.loggedInState = true;
            } else {
                state.token = null;
                state.loggedInState = false;
            }
        },
        setUserInformation: (state, action) => {
            state.userInformation = action.payload;
        },
    },
});

export const { setToken, setUserInformation } = userSlice.actions;

export const selectLoggedInState = (state) => state.user.loggedInState;
export const selectToken = (state) => state.user.token;
export const selectUserInfo = (state) => state.user.userInformation;

export default userSlice.reducer;
