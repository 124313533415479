import {NavigationContainer} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import * as SecureStore from 'expo-secure-store';
import {StatusBar} from 'expo-status-bar';
import AppLoading from 'expo-app-loading';
import {useEffect, useState} from 'react';
import {
	KeyboardAvoidingView,
	Platform,
	StyleSheet,
	Text,
	View,
} from 'react-native';
import tw from './lib/tailwind';
import FirstOpenIndex from './screens/firstOpen';
import {useDeviceContext} from 'twrnc';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import LoginContainer from './screens/login/loginContainer';
import StartScreen from './screens/start';
import Index from './screens/authenticatedScreens';
import {Provider} from 'react-redux';
import {store} from './store';
import ResetPassword from './screens/login/resetPassword';
import {useDispatch} from 'react-redux';
import {setToken, setUserInformation} from './slices/userSlice';

import * as Notifications from 'expo-notifications';
import CreateRating from './screens/createRating';

export default function SubApp() {
	const Stack = createNativeStackNavigator();

	const [isAppReady, setIsAppReady] = useState(false);

	const [isFirstOpen, setIsFirstOpen] = useState(false);

	const dispatch = useDispatch();

	async function testforFirstOpen() {
		try {
			let res = await SecureStore.getItemAsync('ubring_first_open');
			if (JSON.parse(res)) {
				setIsFirstOpen(false);
				return true;
			} else {
				setIsFirstOpen(true);
				await SecureStore.setItemAsync(
					'ubring_first_open',
					JSON.stringify(true)
				);
				return true;
			}
		} catch (error) {
			console.log(error);
			return false;
		}
	}

	async function testForAvaibleLogin() {
		try {
			let res = await SecureStore.getItemAsync('ubring_login');

			if (res) {
				let user = JSON.parse(res);
				dispatch(setToken(user.accessToken));
				dispatch(setUserInformation(user.user));
			}
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		Notifications.setNotificationHandler({
			handleNotification: async () => ({
				shouldShowAlert: true,
				shouldPlaySound: false,
				shouldSetBadge: true,
			}),
		});
		if (Platform.OS === 'android')
			Notifications.setNotificationChannelAsync('default', {
				name: 'default',
				importance: Notifications.AndroidImportance.MAX,
				vibrationPattern: [0, 250, 250, 250],
				lightColor: '#FF231F7C',
			});
	}, []);

	if (!isAppReady) {
		return (
			<AppLoading
				startAsync={() => {
					testforFirstOpen();
					testForAvaibleLogin();
				}}
				onFinish={() => setTimeout(() => setIsAppReady(true), 500)}
				onError={console.warn}
			/>
		);
	} else {
		const linking = {
			prefixes: [
				'http://localhost:19006',
				'ubring://',
				'https://app.u-bring.de',
			],
			config: {
				screens: {
					reset: 'auth/reset?key=:key',
				},
			},
		};

		return (
			<View
				style={tw.style(
					'flex-1 w-full bg-background ' +
						(Platform.OS === 'web' ? 'max-w-[650px] mx-auto' : '')
				)}
			>
				<SafeAreaProvider>
					<StatusBar style="inverted" />
					<NavigationContainer linking={linking}>
						<Stack.Navigator
							initialRouteName={isFirstOpen ? 'firstOpen' : 'start'}
							screenOptions={{
								headerShown: false,
							}}
						>
							<Stack.Screen
								name="firstOpen"
								component={FirstOpenIndex}
								options={{title: 'Willkommen'}}
							/>
							<Stack.Screen
								name="loginContainer"
								component={LoginContainer}
								options={{title: 'Login'}}
							/>
							<Stack.Screen
								name="start"
								component={StartScreen}
								options={{title: 'Start'}}
							/>
							<Stack.Screen name="authenticatedContainer" component={Index} />
							<Stack.Screen
								name="rating"
								component={CreateRating}
								options={{
									title: 'Bewertung erstellen',
									headerShown: true,
									headerBackground: () => (
										<View style={tw.style('flex-1 bg-primary')} />
									),
									headerTitleStyle: tw.style('text-white'),
									headerTintColor: 'white',
									headerBackTitle: 'Zurück',
								}}
							/>
							<Stack.Screen
								name="reset"
								component={ResetPassword}
								options={{title: 'Password zurücksetzen'}}
							/>
						</Stack.Navigator>
					</NavigationContainer>
				</SafeAreaProvider>
			</View>
		);
	}
}
