import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Linking,
	Platform,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {selectToken} from '../../../slices/userSlice';
import tw from '../../../lib/tailwind';
import BackButton from '../../../uiKit/backButton';
import * as Browser from 'expo-web-browser';
import * as IntentLauncher from 'expo-intent-launcher';
import * as Sharing from 'expo-sharing';
import * as FileSystem from 'expo-file-system';

export default function ViewTransportVerträge({navigation}) {
	const token = useSelector(selectToken);

	const [allEntrys, setAllEntrys] = useState([]);

	useEffect(() => {
		fetchData();
	}, []);

	async function fetchData() {
		try {
			let res = await axios.get(process.env.APIURL + 'shipments/all', {
				'headers': {
					'Authorization': 'Bearer ' + token,
				},
			});
			console.log(res.data.data);
			setAllEntrys(res.data.data);
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<ScrollView contentContainerStyle={tw.style('p-6')}>
			<View style={tw.style('flex flex-row justify-between items-center mb-6')}>
				<BackButton onPress={() => navigation.goBack()} />
				<Text style={tw.style('text-xl font-bold w-3/4')}>
					Meine Transportverträge
				</Text>
				<View style={tw.style('w-1/4')} />
			</View>
			{allEntrys.map((entry, index) => {
				return (
					<TouchableOpacity
						key={entry.id}
						style={tw.style(
							'flex flex-row justify-between items-center border-b border-gray-200 py-4'
						)}
						onPress={() => {
							// Browser.openBrowserAsync(
							// 	process.env.APIURL + 'shipmentDoc/TV-' + entry.id + '.pdf'
							// );

							if (Platform.OS == 'web') {
								const aElement = document.createElement('a');
								const href =
									process.env.APIURL + 'shipmentDoc/TV-' + entry.id + '.pdf';
								aElement.href = href;
								aElement.download = 'TV-' + entry.id + '.pdf';
								document.body.appendChild(aElement);
								aElement.click();
								document.body.removeChild(aElement);
								return;
							}

							FileSystem.downloadAsync(
								process.env.APIURL + 'shipmentDoc/TV-' + entry.id + '.pdf',
								FileSystem.documentDirectory + 'TV-' + entry.id + '.pdf'
							)
								.then(({uri}) => {
									FileSystem.getContentUriAsync(uri).then((cUri) => {
										if (Platform.OS == 'ios') {
											Sharing.shareAsync(cUri);
										} else {
											IntentLauncher.startActivityAsync(
												'android.intent.action.VIEW',
												{
													data: cUri,
													flags: 1,
													type: 'application/pdf',
												}
											);
										}
									});
								})
								.catch((error) => {
									console.error(error);
								});
						}}
					>
						<Text style={tw.style('text-xl w-2/4')}>{entry.name}</Text>
						<Text style={tw.style('underline')}>ansehen</Text>
					</TouchableOpacity>
				);
			})}
		</ScrollView>
	);
}
