import {
	View,
	Text,
	TouchableWithoutFeedback,
	Platform,
	Keyboard,
	KeyboardAvoidingView,
	Alert,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import tw from '../../lib/tailwind';
import HeaderBar from '../../uiKit/headerBar';
import Input from '../../uiKit/input';
import {useSelector} from 'react-redux';
import {selectHeaderBarLayout} from '../../slices/headerBarSlice';
import BackButton from '../../uiKit/backButton';
import NextButton from '../../uiKit/nextButton';
import axios from 'axios';

export default function ResetPassword({navigation, route}) {
	const HeaderBarLayout = useSelector(selectHeaderBarLayout);

	const [buttonLoading, setButtonLoading] = useState(false);
	const [buttonDisabled, setButtonDisabled] = useState(true);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordRepeat, setPasswordRepeat] = useState('');
	const [passwordPoints, setPasswordPoints] = useState(0);
	const [passwordStrongText, setPasswordStrongText] = useState('Kein Password');
	const [passwordStrongColor, setPasswordStrongColor] = useState('gray-300');
	var resetKey = route.params.key;

	useEffect(() => {
		if (!resetKey) {
			navigation.navigate('/start');
		}
	}, [resetKey]);

	function resetPassword() {
		setButtonLoading(true);
		//console.log(resetKey);
		axios
			.post(
				process.env.APIURL + 'user/reset',
				{email: email.trim(), pass: password.trim(), key: resetKey},
				{
					'headers': {
						'content-type': 'application/json',
					},
				}
			)
			.then((res) => {
				//console.log(res.data);
				setButtonLoading(false);
				navigation.replace('start');
				Alert.alert(
					'Password erfolgreich geändert',
					'Du kannst dich nun mit deinem neuen Password einloggen.'
				);
				if (Platform.OS === 'web') {
					alert('Du kannst dich nun mit deinem neuen Password einloggen.');
				}
			})
			.catch((e) => {
				console.log(e);
				Alert.alert(
					'Fehler',
					'Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.'
				);
				setButtonLoading(false);
			});
	}

	useEffect(() => {
		var tempPass = password.trim();
		var tempPassRepeat = passwordRepeat.trim();

		var inclCapitalLetters = tempPass.match(/[A-Z]+/);
		var inclSmallLetters = tempPass.match(/[a-z]+/);
		var inclNumbers = tempPass.match(/[0-9]+/);
		var inclSpecialChar = tempPass.match(
			/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
		);
		var longEnough = tempPass.length >= 6;

		if (longEnough) {
			var temp = [
				inclCapitalLetters,
				inclSmallLetters,
				inclNumbers,
				longEnough,
				inclSpecialChar,
			];

			var safety = temp.filter((value) => value).length * 2;
			setPasswordPoints(safety);
		} else if (tempPass.length > 0 && tempPass.length < 6) {
			setPasswordPoints(1);
		} else {
			setPasswordPoints(0);
		}

		if (passwordPoints > 4) {
			if (tempPass === tempPassRepeat) {
				setButtonDisabled(false);
			} else {
				setButtonDisabled(true);
			}
		} else {
			setButtonDisabled(true);
		}
	}, [password, passwordRepeat, passwordPoints]);

	useEffect(() => {
		switch (passwordPoints) {
			case 0:
				setPasswordStrongText('Kein Passwort');
				setPasswordStrongColor('gray-300');
				break;
			case 1:
				setPasswordStrongText('Passwort zu kurz');
				setPasswordStrongColor('gray-300');
				break;
			case 2:
				setPasswordStrongText('Sehr Schwach!');
				setPasswordStrongColor('red-400');
				break;

			case 3:
			case 4:
				setPasswordStrongText('Schwach!');
				setPasswordStrongColor('red-200');
				break;

			case 5:
			case 6:
				setPasswordStrongText('Stark!');
				setPasswordStrongColor('yellow-400');
				break;

			case 7:
			case 8:
			case 9:
				setPasswordStrongText('Sehr Stark!');
				setPasswordStrongColor('green-200');
				break;

			case 10:
				setPasswordStrongText('Super Stark!');
				setPasswordStrongColor('green-400');
				break;

			default:
				break;
		}
	}, [passwordPoints]);

	return (
		<TouchableWithoutFeedback
			onPress={() => {
				if (Platform.OS != 'web') {
					Keyboard.dismiss();
				}
			}}
		>
			<View style={tw.style('flex-1 bg-background')}>
				<HeaderBar siteTitle="U-login" />
				<View style={tw.style('flex-1 justify-between px-6')}>
					<View style={tw.style('')}>
						<Text style={tw.style('font-bold text-xl my-7 text-center')}>
							Neues Passwort bestätigen
						</Text>
						<Input
							onChangeText={(text) => setEmail(text)}
							value={email}
							accessibilityLabel="E-Mail"
							accessibilityRole="email"
							clearButtonMode="while-editing"
							placeholder="E-Mail"
						/>
						<Input
							onChangeText={(text) => setPassword(text)}
							value={password}
							secureTextEntry={true}
							accessibilityLabel="Passwort eingeben"
							accessibilityRole="text"
							clearButtonMode="while-editing"
							placeholder="Passwort eingeben"
						/>
						<Text
							style={tw.style('text-xs text-gray-400 font-light mt--2 mb-4')}
						>
							(min. 6 Zeichen, Groß- und Kleinbuchstaben, eine Ziffer, ein
							Sonderzeichen)
						</Text>
						<View
							style={tw.style(
								'w-1/2 flex items-center justify-center p-3 rounded-xl mb-2 bg-' +
									passwordStrongColor
							)}
						>
							<Text>{passwordStrongText}</Text>
						</View>

						<Input
							onChangeText={(text) => setPasswordRepeat(text)}
							value={passwordRepeat}
							secureTextEntry={true}
							accessibilityLabel="Passwort wiederholen"
							accessibilityRole="text"
							clearButtonMode="while-editing"
							placeholder="Passwort wiederholen"
						/>
						<Text
							style={tw.style(
								'text-xs text-red-400 font-medium mt--2 mb-4 ' +
									(password !== '' &&
									passwordRepeat !== '' &&
									password !== passwordRepeat
										? ''
										: 'hidden')
							)}
						>
							Passwörter sind nicht gleich
						</Text>
					</View>

					<KeyboardAvoidingView
						behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
						keyboardVerticalOffset={HeaderBarLayout.height + 14}
					>
						<View style={tw.style('flex-row justify-end w-full mb-4 h-14')}>
							<NextButton
								onPress={() => resetPassword()}
								label="Zurücksetzen"
								showIcon={buttonLoading}
								loading={buttonLoading}
								disabled={buttonLoading || buttonDisabled}
							/>
						</View>
					</KeyboardAvoidingView>
				</View>
			</View>
		</TouchableWithoutFeedback>
	);
}
