import React, {useEffect} from 'react';
import {
	View,
	Text,
	TouchableHighlight,
	TouchableOpacity,
	ScrollView,
	Image,
	Linking,
} from 'react-native';
import {Avatar, Icon, Rating} from 'react-native-elements';
import {SafeAreaView} from 'react-native-safe-area-context';
import {useDispatch, useSelector} from 'react-redux';
import tw from '../../../lib/tailwind';
import {
	selectToken,
	selectUserInfo,
	setToken,
	setUserInformation,
} from '../../../slices/userSlice';
import appInfo from '../../../app.json';

import * as SecureStore from 'expo-secure-store';
import axios from 'axios';
import Ratings from '../../../uiKit/Ratings';

import LocationPointer from '../../../assets/images/icons/location-pointer.png';
import Package from '../../../assets/images/icons/package.png';
import Check from '../../../assets/images/icons/check.png';
import Logout from '../../../assets/images/icons/logout.png';
import User from '../../../assets/images/icons/user.png';
import SettingsCog from '../../../assets/images/icons/settings-cog.png';
import TrendUp from '../../../assets/images/icons/trend-up.png';
import Chat from '../../../assets/images/icons/chat.png';
import Folder from '../../../assets/images/icons/folder.png';
import Invoice from '../../../assets/images/icons/invoices.png';
import {MaterialIcons} from '@expo/vector-icons';

export default function UAndMe({navigation}) {
	const userInfo = useSelector(selectUserInfo);
	//console.log(userInfo);

	const dispatch = useDispatch();

	const token = useSelector(selectToken);

	const ListEntrys = [
		{
			icon: LocationPointer,
			label: 'Meine Routen',
			route: 'uBring',
			screen: 'route',
		},
		{icon: Package, label: 'Meine Sendungen', route: 'uMission'},
		{icon: Check, label: 'Meine Anfragen', route: 'viewRequests'},
		{
			icon: Folder,
			label: 'Meine Transportverträge',
			route: 'viewTransportVertrage',
		},
		{
			icon: Invoice,
			label: 'Meine Rechnungen',
			route: 'viewInvoices',
		},
		{icon: Chat, label: 'Meine Bewertungen', route: 'viewReviews'},
		{icon: TrendUp, label: 'Statistik', route: 'statistics'},
		{icon: SettingsCog, label: 'Konto bearbeiten', route: 'editProfile'},
	];

	const legalLinks = [
		{
			label: 'AGB',
			link: 'https://u-bring.de/agb',
		},
		{
			label: 'Datenschutz',
			link: 'https://u-bring.de/datenschutz',
		},
		{
			label: 'Impressum',
			link: 'https://u-bring.de/impressum',
		},
	];

	async function handleLogout() {
		try {
			let res = await axios.post(
				process.env.APIURL + 'logout',
				{},
				{
					'headers': {
						'Authorization': 'Bearer ' + token,
					},
				}
			);

			await SecureStore.deleteItemAsync('ubring_login');
		} catch (error) {
			console.log(error);
		}
		navigation.navigate('start', {merge: true});
		dispatch(setUserInformation(null));
		dispatch(setToken());
	}

	return (
		<ScrollView>
			<SafeAreaView edges={['left', 'right']} style={tw.style('flex-1 px-6')}>
				<View style={tw.style('flex-1')}>
					<View style={tw.style('w-full flex flex-row items-center my-4 mb-8')}>
						<Avatar
							source={{
								uri:
									userInfo.avatarSrc !== ''
										? userInfo.avatarSrc
										: 'https://api.multiavatar.com/' + userInfo.email + '.png',
							}}
							size={64}
							rounded
							containerStyle={tw.style('shadow-md bg-white')}
						>
							<TouchableOpacity
								style={tw.style(
									'absolute -bottom-2 -right-2 bg-gray-300 rounded-full p-1'
								)}
								onPress={() => {
									navigation.push('editProfile');
								}}
							>
								<MaterialIcons
									name="edit"
									size={16}
									color={tw.color('gray-700')}
								/>
							</TouchableOpacity>
						</Avatar>
						<View style={tw.style('flex-1 ml-6')}>
							<View
								style={tw.style('flex-1 flex-row justify-between items-center')}
							>
								<Text style={tw.style('text-xl font-bold')}>
									{userInfo.firstname + ' ' + userInfo.lastname}
								</Text>
							</View>
							<View
								style={tw.style('flex-1 flex-row justify-between items-center')}
							>
								<Text style={tw.style('text-base font-light')}>
									{userInfo.email}
								</Text>
							</View>
							<View
								style={tw.style('flex-1 flex-row justify-between items-center')}
							>
								<Ratings userID={userInfo.id} />
							</View>
						</View>
					</View>
					{ListEntrys.map((entry, id) => (
						<TouchableOpacity
							key={id}
							onPress={() =>
								navigation.navigate(
									entry.route,
									entry.screen ? {screen: entry.screen} : {}
								)
							}
						>
							<View
								style={tw.style(
									'py-4 w-full border-b border-gray-200 flex flex-row items-center justify-between'
								)}
							>
								<View
									style={tw.style(
										'flex flex-row items-center                                                   '
									)}
								>
									<Image
										source={entry.icon}
										style={[
											tw.style('mr-3 w-5 h-5'),
											{tintColor: tw.color('gray-700')},
										]}
										resizeMode="contain"
									/>
									<Text>{entry.label}</Text>
								</View>
								<MaterialIcons
									name="chevron-right"
									containerStyle={tw.style('mr-3')}
									color={tw.color('gray-700')}
								/>
							</View>
						</TouchableOpacity>
					))}
					{userInfo.extended && (
						<TouchableOpacity
							onPress={() => navigation.navigate('bringerData')}
						>
							<View
								style={tw.style(
									'py-4 w-full border-b border-gray-200 flex flex-row items-center justify-between'
								)}
							>
								<View
									style={tw.style(
										'flex flex-row items-center                                                   '
									)}
								>
									<Image
										source={User}
										style={[
											tw.style('mr-3 w-5 h-5'),
											{tintColor: tw.color('gray-700')},
										]}
										resizeMode="contain"
									/>
									<Text>Deine Bringerdaten</Text>
								</View>
								<MaterialIcons
									name="chevron-right"
									containerStyle={tw.style('mr-3')}
									color={tw.color('gray-700')}
								/>
							</View>
						</TouchableOpacity>
					)}
					<TouchableOpacity onPress={() => handleLogout()}>
						<View
							style={tw.style(
								'py-4 w-full flex flex-row items-center justify-between'
							)}
						>
							<View
								style={tw.style(
									'flex flex-row items-center                                                   '
								)}
							>
								<Image
									source={Logout}
									style={[
										tw.style('mr-3 w-5 h-5 '),
										{tintColor: tw.color('red-400')},
									]}
									resizeMode="contain"
								/>
								<Text style={tw.style('text-red-400')}>Abmelden</Text>
							</View>
						</View>
					</TouchableOpacity>
					<Text style={tw.style('text-center font-thin my-6')}>
						App-Version: Beta-{appInfo.expo.version}
					</Text>

					<View style={tw.style('flex justify-center')}>
						{legalLinks.map((link, id) => (
							<TouchableOpacity
								key={id}
								onPress={() => Linking.openURL(link.link)}
								style={tw.style('mb-3')}
							>
								<Text style={tw.style('text-center font-medium underline')}>
									{link.label}
								</Text>
							</TouchableOpacity>
						))}
					</View>
				</View>
			</SafeAreaView>
		</ScrollView>
	);
}
