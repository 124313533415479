// tailwind.config.js

module.exports = {
    purge: [],
    theme: {
        extend: {
            colors: {
                'primary': '#42D892',
                'primary-dark': '#038C5A',
                'accent': '#FFB169',
                'background': '#F6F6F6',
            },
            width: {
                '240': '240px',
            },
        },
    },
    variants: {
        extend: {
            background: ['active'],
        },
    },
    plugins: [],
};
