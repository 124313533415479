import React, {createRef, useEffect, useRef, useState} from 'react';
import {
	View,
	Text,
	Image,
	TouchableOpacity,
	Platform,
	ActivityIndicator,
	AppState,
} from 'react-native';
import tw from '../lib/tailwind';

import MapViewCluster from 'react-native-map-clustering';
import * as Location from 'expo-location';

import MarkerHome from '../assets/images/icons/map/Home_MapIcon.png';
import MarkerPackage from '../assets/images/icons/map/Package_MapIcon.png';

import {useDispatch, useSelector} from 'react-redux';
import {
	selectLoggedInState,
	selectToken,
	selectUserInfo,
	setUserInformation,
} from '../slices/userSlice';
import HeaderBar from '../uiKit/headerBar';
import StartMenuButton from '../uiKit/startMenuButton';
import axios from 'axios';
import {useIsFocused} from '@react-navigation/native';
import MapView from 'react-native-maps';
import {Marker} from 'react-native-maps';
import * as Linking from 'expo-linking';

export default function StartScreen({navigation}) {
	const [currentLocation, setCurrentLocation] = useState({});

	const [packages, setPackages] = useState([]);

	const isLoggedIn = useSelector(selectLoggedInState);
	const userInfo = useSelector(selectUserInfo);
	const savedToken = useSelector(selectToken);

	const initialUrl = Linking.useURL();

	useEffect(() => {
		// If there is an initial URL, parse and handle it
		if (initialUrl) {
			console.log('initialUrl', initialUrl);
			handleDeepLink(initialUrl);
		}
	}, [initialUrl]);

	useEffect(() => {
		let stateListener = AppState.addEventListener('change', (state) => {
			if (state === 'active') {
				// check for deep links
				handleDeepLink(initialUrl);
			}
		});

		return () => {
			stateListener.remove();
		};
	}, []);

	const handleDeepLink = (url) => {
		if (!url) return;
		// Parse the link
		const {hostname, path, queryParams} = Linking.parse(url);
		// Navigate to the appropriate screen or content based on the link
		if (!path) {
			return;
		}
		console.log('handleDeepLink 1 ', path, queryParams);
		if (path == 'auth/reset') {
			console.log('handleDeepLink2 ', path, queryParams);
			navigation.navigate({
				name: 'reset',
				params: queryParams,
			});
		}
	};
	const [token, setToken] = useState('');

	const currentlyFocused = useIsFocused();

	const [loadingPackages, setLoadingPackages] = useState(true);

	const [mapViewCurrentRegion, setMapViewCurrentRegion] = useState({
		latitude: 51.37052,
		longitude: 10.52277,
		latitudeDelta: 8,
		longitudeDelta: 8,
	});

	useEffect(() => {
		getLocation();
	}, []);

	useEffect(() => {
		getPackagesByLocation();
	}, [currentLocation]);

	useEffect(() => {
		if (currentlyFocused) {
			getPackagesByLocation();
		}
	}, [savedToken, currentlyFocused]);
	let packagesRef = packages.map(() => createRef());

	async function getLocation() {
		await Location.enableNetworkProviderAsync();

		let statusLocation = await Location.requestForegroundPermissionsAsync();

		if (!statusLocation.granted) {
			console.log('Location was denied');

			return;
		}

		let lastKnownLocation = await Location.getLastKnownPositionAsync({});

		if (lastKnownLocation?.coords) {
			setCurrentLocation(lastKnownLocation);
			setMapViewCurrentRegion({
				latitude: lastKnownLocation.coords.latitude,
				longitude: lastKnownLocation.coords.longitude,
				latitudeDelta: 0.1,
				longitudeDelta: 0.1,
			});
		}

		let location = await Location.getCurrentPositionAsync({
			accuracy: Location.Accuracy.Highest,
		});

		if (location.coords) {
			setCurrentLocation(location);
			setMapViewCurrentRegion({
				latitude: location.coords.latitude,
				longitude: location.coords.longitude,
				latitudeDelta: 0.1,
				longitudeDelta: 0.1,
			});
		} else {
			console.log('Location not found');
			alert('Location not found');
		}
	}

	async function getPackagesByLocation() {
		if (savedToken) {
			setToken(savedToken);
		} else {
			var res = await axios
				.post(
					process.env.APIURL + 'token',
					{
						username: 'demo@demo.de',
						password: 'ifrOGqpn3EiV5C*$lfDNv0%j',
					},
					{
						'headers': {
							'content-type': 'application/json',
						},
					}
				)
				.catch((e) => {
					console.log('Error Packages by Location with Demo:', e.response);
				});

			if (res?.data) {
				const data = res.data.data;
				setToken(data.accessToken);
			}
		}

		if (currentLocation.coords && token !== '') {
			setLoadingPackages(true);
			axios
				.post(
					process.env.APIURL + 'shipments/radius',
					{
						latitude: currentLocation.coords.latitude,
						longitude: currentLocation.coords.longitude,
					},
					{
						headers: {'Authorization': `Bearer ${token}`},
					}
				)
				.then((res) => {
					if (res?.data) {
						console.log('Got data!');

						setPackages(res.data);
						setLoadingPackages(false);
					}
				})
				.catch((e) => {
					console.log('Error all Packages: ', e.response);
					setLoadingPackages(false);
				});
		}
	}

	function navigateWhenAuthenticated(screen) {
		if (isLoggedIn) {
			navigation.push('authenticatedContainer', {
				screen,
			});
		} else {
			navigation.push('loginContainer', {
				screen: 'login',
				params: {
					screen,
				},
			});
		}
	}

	return (
		<View style={tw.style('flex-1')}>
			<HeaderBar siteTitle="U-BRING" />

			<View style={tw.style('flex-1 items-center bg-background')}>
				{Platform.OS !== 'web' ? (
					<MapViewCluster
						initialRegion={{
							latitude: 51.37052,
							longitude: 10.52277,
							latitudeDelta: 5,
							longitudeDelta: 5,
						}}
						loadingIndicatorColor={tw.color('primary')}
						loadingBackgroundColor={tw.color('gray-300')}
						region={mapViewCurrentRegion}
						onRegionChangeComplete={(region) => {
							setMapViewCurrentRegion(region);
						}}
						mapType="standard"
						style={tw.style('w-full h-2/5 relative')}
						loadingEnabled={true}
					>
						<Marker
							coordinate={{
								latitude: currentLocation.coords?.latitude || 0,
								longitude: currentLocation.coords?.longitude || 0,
							}}
							style={{zIndex: 10, width: 50, height: 50}}
							onPress={() => {
								setMapViewCurrentRegion({
									latitude: currentLocation.coords?.latitude || 0,
									longitude: currentLocation.coords?.longitude || 0,
									latitudeDelta: 0.1,
									longitudeDelta: 0.1,
								});
							}}
							tracksViewChanges={false}
							image={MarkerHome}
							cluster={false}
						>
							{/* <Image
								onLoad={() => homemarkerRef.current.redraw()}
								source={MarkerHome}
								resizeMode="contain"
							/> */}
						</Marker>
						{packages.map((data, index) => {
							var formatedPrice = data.price / 100;

							formatedPrice = formatedPrice.toFixed(2).replace('.', ',') + ' €';

							return (
								<Marker
									coordinate={{
										latitude: parseFloat(data.latitude),
										longitude: parseFloat(data.longitude),
									}}
									key={data.id}
									icon={Platform.OS === 'web' && MarkerPackage}
									style={{zIndex: 1000}}
									tracksViewChanges={false}
									onPress={() => navigateWhenAuthenticated('uBring')}
									// image={MarkerPackage}
									ref={packagesRef[index]}
								>
									<View
										style={tw.style(
											'flex justify-center items-center px-3 pt-3'
										)}
									>
										<View
											style={tw.style(
												'bg-white rounded-lg p-3 shadow-md mb-1.5'
											)}
										>
											<Text>{formatedPrice}</Text>
										</View>

										<Image
											source={MarkerPackage}
											resizeMode="contain"
											onLoad={() => {
												if (packagesRef[index].current)
													packagesRef[index].current.redraw();
											}}
										/>
									</View>
								</Marker>
							);
						})}
					</MapViewCluster>
				) : (
					<MapView
						initialRegion={{
							latitude: 51.37052,
							longitude: 10.52277,
							latitudeDelta: 5,
							longitudeDelta: 5,
						}}
						loadingIndicatorColor={tw.color('primary')}
						loadingBackgroundColor={tw.color('gray-300')}
						region={mapViewCurrentRegion}
						onRegionChangeComplete={(region) => {
							setMapViewCurrentRegion(region);
						}}
						mapType="standard"
						style={tw.style('w-full h-2/5 relative')}
						loadingEnabled={true}
						maxZoom={16}
						maxZoomLevel={18}
					>
						<MapView.Marker
							coordinate={{
								latitude: currentLocation.coords?.latitude || 0,
								longitude: currentLocation.coords?.longitude || 0,
							}}
							icon={Platform.OS === 'web' && MarkerHome}
							style={{zIndex: 10}}
							onPress={() => {
								setMapViewCurrentRegion({
									latitude: currentLocation.coords?.latitude || 0,
									longitude: currentLocation.coords?.longitude || 0,
									latitudeDelta: 0.1,
									longitudeDelta: 0.1,
								});
							}}
							tracksViewChanges={false}
						>
							<Image source={MarkerHome} resizeMode="contain" />
						</MapView.Marker>
						{packages.map((data, index) => {
							var formatedPrice = data.price / 100;

							formatedPrice = formatedPrice.toFixed(2).replace('.', ',') + ' €';

							return (
								<MapView.Marker
									coordinate={{
										latitude: parseFloat(data.latitude),
										longitude: parseFloat(data.longitude),
									}}
									key={index}
									icon={Platform.OS === 'web' && MarkerPackage}
									style={{zIndex: 1000}}
									onPress={() => navigateWhenAuthenticated('uBring')}
									tracksViewChanges={false}
								>
									<View
										style={tw.style(
											'flex justify-center items-center px-3 pt-3'
										)}
									>
										<View
											style={tw.style(
												'bg-white rounded-lg p-3 shadow-md mb-1.5'
											)}
										>
											<Text>{formatedPrice}</Text>
										</View>

										<Image source={MarkerPackage} resizeMode="contain" />
									</View>
								</MapView.Marker>
							);
						})}
					</MapView>
				)}
				<Text style={{opacity: 0}}>Alle Aufträge in der näheren Umgebung</Text>
				<View style={tw.style('w-2/3')}>
					<StartMenuButton
						title="Senden"
						color="yellow"
						onPress={() => navigateWhenAuthenticated('uSend')}
					/>
					<StartMenuButton
						title="Bringen"
						color="primary"
						onPress={() => navigateWhenAuthenticated('uBring')}
					/>
					<StartMenuButton
						title="Aufträge"
						color="gray"
						onPress={() => navigateWhenAuthenticated('uMission')}
					/>
				</View>
			</View>
		</View>
	);
}
