import { configureStore } from '@reduxjs/toolkit';
import registerUserSlice from './slices/registerUserSlice';
import userReducer from './slices/userSlice';
import headerBarSlice from './slices/headerBarSlice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        registerNewUser: registerUserSlice,
        headerBar: headerBarSlice,
    },
});
