import {
	View,
	Text,
	TouchableOpacity,
	Image,
	TouchableWithoutFeedback,
	Alert,
	Keyboard,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import tw from '../../../lib/tailwind';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';
import {BottomSheet, CheckBox, Icon, ListItem} from 'react-native-elements';

import Input from '../../../uiKit/input';
import {useDispatch, useSelector} from 'react-redux';
import {
	selectToken,
	selectUserInfo,
	setUserInformation,
} from '../../../slices/userSlice';
import axios from 'axios';
import {selectExtendedInformation} from '../../../slices/registerUserSlice';

import * as FileSystem from 'expo-file-system';
import {Platform} from 'react-native';

// Cars

import Bike from './../../../assets/images/icons/cars/Ubring_Auto_Motorrad.png';
import Klein from '../../../assets/images/icons/cars/Ubring_Auto_Kleinwagen.png';
import Kompakt from './../../../assets/images/icons/cars/Ubring_Auto_Golf.png';
import Limo from '../../../assets/images/icons/cars/Ubring_Auto_Limousine.png';
import Kombi from '../../../assets/images/icons/cars/Ubring_Auto_Kombi.png';
import SUV from '../../../assets/images/icons/cars/Ubring_Auto_SUV.png';
import Van from '../../../assets/images/icons/cars/Ubring_Auto_Van.png';
import Kasten from '../../../assets/images/icons/cars/Ubring_Auto_Transporter.png';
import LKW from '../../../assets/images/icons/cars/Ubring_Auto_KleinLaster.png';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
import {MaterialIcons} from '@expo/vector-icons';

export default function VehicleType({navigation}) {
	function sub(base, exponent) {
		return (
			<View style={{flexDirection: 'row'}}>
				<View style={{alignItems: 'flex-end'}}>
					<Text style={tw.style('')}>{base}</Text>
				</View>
				<View style={{alignItems: 'flex-start'}}>
					<Text style={tw.style('text-xs')}>{exponent}</Text>
				</View>
			</View>
		);
	}

	const [isVisible, setIsVisible] = useState(false);

	const [hasTrailerHitch, setHasTrailerhitch] = useState(false);
	const [hasRoofRack, setHasRoofRack] = useState(false);
	const [hasBikeRack, setHasBikeRack] = useState(false);

	const [buttonDisabled, setButtonDisabled] = useState(true);
	const [buttonLoading, setButtonLoading] = useState(false);

	const token = useSelector(selectToken);

	const [packageVolume, setPackageVolume] = useState('');

	const [list, setList] = useState([
		{
			allowance: '0',
			createdAt: '2022-06-17 14:50:21',
			description: '',
			icon: '',
			id: 'loadingID',
			modifiedAt: '2022-06-17 14:51:07',
			name: 'Wird geladen...',
			volume: '0',
		},
	]);
	const [selectedCar, setSelectedCar] = useState({
		allowance: '0',
		createdAt: '2022-06-17 14:50:21',
		description: '',
		icon: '',
		id: 'selectedID',
		modifiedAt: '2022-06-17 14:51:07',
		name: 'Fahrzeugtyp wählen',
		volume: '0',
	});

	const userInfo = useSelector(selectUserInfo);

	const dispatch = useDispatch();

	const extendedData = useSelector(selectExtendedInformation);

	function getIcon(icon) {
		switch (icon) {
			case 'motorrad':
				return Bike;

			case 'klein':
				return Klein;

			case 'kompakt':
				return Kompakt;

			case 'limo':
				return Limo;

			case 'kombi':
				return Kombi;

			case 'suv':
				return SUV;

			case 'van':
				return Van;

			case 'kasten':
				return Kasten;

			case 'lkw':
				return LKW;

			default:
				break;
		}
	}

	async function saveBringer() {
		setButtonLoading(true);
		setButtonDisabled(true);
		let formdata = new FormData();
		let exportedFileContent;

		try {
			exportedFileContent = await FileSystem.readAsStringAsync(
				extendedData.licenseData.image.uri,
				{encoding: FileSystem.EncodingType.Base64}
			);
		} catch (error) {
			console.log(error);

			return;
		}

		let uri = extendedData.licenseData.image.uri;
		let fileExtension = uri.substr(uri.lastIndexOf('.') + 1);
		console.log(extendedData.licenseData.image);

		let img_to_upload = {
			type: 'image/' + fileExtension,
			name: 'license-' + userInfo.id + '.' + fileExtension,
			uri: extendedData.licenseData.image.uri,
		};

		formdata.append('license', img_to_upload);
		formdata.append('licenseplate', extendedData.licensePlate);
		formdata.append('art', selectedCar.name);
		formdata.append('towhook', hasTrailerHitch ? '1' : '0');
		formdata.append('roofrack', hasRoofRack ? '1' : '0');
		formdata.append('bikerack', hasBikeRack ? '1' : '0');
		formdata.append('volume', parseInt(packageVolume));

		try {
			let res = await axios.post(
				process.env.APIURL + 'user/validate',
				formdata,
				{
					headers: {
						'Authorization': 'Bearer ' + token,
						'Content-Type': 'multipart/form-data',
					},
					transformRequest: (formdata) => formdata,
				}
			);

			setButtonDisabled(false);
			setButtonLoading(false);

			dispatch(setUserInformation(res.data.data));
			navigation.navigate('thankYou');
		} catch (error) {
			console.log(error.response.data);

			Alert.alert(
				'Fehler',
				error.response.data ||
					'Es ist ein Fehler aufgetreten. Bitte versuche es erneut.'
			);

			setButtonDisabled(false);
			setButtonLoading(false);
		}
	}

	useEffect(() => {
		getAllVehicles();
	}, []);

	async function getAllVehicles() {
		try {
			let res = await axios.get(
				process.env.APIURL + 'user/validate/getAllVehicles',
				{
					headers: {'Authorization': `Bearer ${token}`},
				}
			);

			setList(res.data);
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<TouchableWithoutFeedback
			onPress={() => {
				if (Platform.OS != 'web') {
					Keyboard.dismiss();
				}
			}}
			style={tw.style('flex-1')}
		>
			<KeyboardAwareScrollView
				enableAutomaticScroll
				enableOnAndroid
				nestedScrollEnabled={true}
				keyboardShouldPersistTaps="handled"
				style={tw.style('flex-1')}
				contentContainerStyle={tw.style('min-h-full')}
			>
				<View style={tw.style('flex-1 flex justify-between')}>
					<BottomSheet modalProps={{}} isVisible={isVisible}>
						{list.map((l, i) => (
							<ListItem
								key={i}
								containerStyle={l.containerStyle}
								onPress={() => {
									setPackageVolume(l.volume);
									setIsVisible(false);
									setSelectedCar(l);
									setButtonDisabled(false);
								}}
								bottomDivider
							>
								<View style={tw.style('flex flex-row')}>
									<ListItem.Content style={tw.style('flex-1')}>
										<ListItem.Title style={tw.style('')}>
											{l.name}
										</ListItem.Title>
										<ListItem.Subtitle>{l.description}</ListItem.Subtitle>
									</ListItem.Content>
									<Image
										source={getIcon(l.icon)}
										style={[tw.style('h-10 w-15'), {transform: [{scale: 2}]}]}
										resizeMode="contain"
									/>
								</View>
							</ListItem>
						))}
						<TouchableWithoutFeedback onPress={() => setIsVisible(false)}>
							<View
								style={tw.style(
									'w-full bg-white border-t border-gray-300 py-4 px-6 flex flex-row items-center'
								)}
							>
								<MaterialIcons name="close" />
								<Text style={tw.style('ml-3')}>Schließen</Text>
							</View>
						</TouchableWithoutFeedback>
					</BottomSheet>

					<Text
						style={tw.style(
							'text-lg font-bold text-gray-700 text-center mt-7 mb-10'
						)}
					>
						Fahrzeugtyp
					</Text>

					<TouchableOpacity
						style={tw.style(
							'bg-white rounded-lg shadow-md p-4 mx-12 overflow-hidden flex items-center flex flex-row justify-between'
						)}
						onPress={() => setIsVisible(true)}
					>
						<View style={tw.style('w-1/2 pr-2')}>
							<Text style={tw.style('text-base')}>{selectedCar.name}</Text>
							<Text style={tw.style('text-sm font-light')} numberOfLines={1}>
								{selectedCar.description}
							</Text>
						</View>
						<View style={tw.style('flex flex-row items-center')}>
							<Image
								style={tw.style('h-12 w-30 mx-2')}
								source={getIcon(selectedCar.icon)}
							/>
							<MaterialIcons name="unfold-more" size={28} />
						</View>
					</TouchableOpacity>
					<View>
						<TouchableWithoutFeedback
							style={tw.style('')}
							onPress={() => setHasTrailerhitch(!hasTrailerHitch)}
						>
							<View
								style={tw.style(
									'px-6 flex flex-row justify-between items-center'
								)}
							>
								<Text>Anhängerkupplung</Text>
								<CheckBox
									checked={hasTrailerHitch}
									onPress={() => setHasTrailerhitch(!hasTrailerHitch)}
									containerStyle={tw.style(
										'bg-background shadow-none border-0 bg-opacity-0'
									)}
									checkedIcon={
										<MaterialIcons
											name="check-box"
											color={tw.color('primary')}
										/>
									}
									uncheckedIcon={
										<MaterialIcons
											name="check-box-outline-blank"
											color={tw.color('primary')}
										/>
									}
								/>
							</View>
						</TouchableWithoutFeedback>
						<TouchableWithoutFeedback
							style={tw.style('')}
							onPress={() => setHasRoofRack(!hasRoofRack)}
						>
							<View
								style={tw.style(
									'px-6 flex flex-row justify-between items-center'
								)}
							>
								<Text>Dachgepäckträger</Text>
								<CheckBox
									checked={hasRoofRack}
									onPress={() => setHasRoofRack(!hasRoofRack)}
									containerStyle={tw.style(
										'bg-background shadow-none border-0 bg-opacity-0'
									)}
									checkedIcon={
										<MaterialIcons
											name="check-box"
											color={tw.color('primary')}
										/>
									}
									uncheckedIcon={
										<MaterialIcons
											name="check-box-outline-blank"
											color={tw.color('primary')}
										/>
									}
								/>
							</View>
						</TouchableWithoutFeedback>
						<TouchableWithoutFeedback
							style={tw.style('')}
							onPress={() => setHasBikeRack(!hasBikeRack)}
						>
							<View
								style={tw.style(
									'px-6 flex flex-row justify-between items-center'
								)}
							>
								<Text>Fahrradträger</Text>
								<CheckBox
									checked={hasBikeRack}
									onPress={() => setHasBikeRack(!hasBikeRack)}
									containerStyle={tw.style(
										'bg-background shadow-none border-0 bg-opacity-0'
									)}
									checkedIcon={
										<MaterialIcons
											name="check-box"
											color={tw.color('primary')}
										/>
									}
									uncheckedIcon={
										<MaterialIcons
											name="check-box-outline-blank"
											color={tw.color('primary')}
										/>
									}
								/>
							</View>
						</TouchableWithoutFeedback>
					</View>
					<View style={tw.style('bg-gray-300 h-.25 w-full')} />
					<View style={tw.style('px-6')}>
						<Text style={tw.style('font-semibold text-gray-600')}>
							Kofferraumvolumen
						</Text>
						<View style={tw.style('flex flex-row items-center mt-4')}>
							<Text style={tw.style('mb-3 mr-4')}>Volumen in Liter</Text>
							<Input
								value={packageVolume.toString()}
								onChangeText={(text) => {
									setPackageVolume(text);
									setButtonDisabled(text === '');
								}}
								disabled
								placeholder="0,0"
								width={25}
								textCenter
								textColor="gray-600 font-bold"
								keyboardType="numeric"
							/>
						</View>
					</View>

					<View style={tw.style('flex flex-row mx-6 mb-4')}>
						<BackButton onPress={() => navigation.goBack()} />
						<NextButton
							label="Speichern"
							showIcon
							loading={buttonLoading}
							disabled={buttonDisabled}
							onPress={() => saveBringer()}
						/>
					</View>
				</View>
			</KeyboardAwareScrollView>
		</TouchableWithoutFeedback>
	);
}
