import {NavigationContainer} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import * as SecureStore from 'expo-secure-store';
import {StatusBar} from 'expo-status-bar';
import AppLoading from 'expo-app-loading';
import {useEffect, useState} from 'react';
import {
	KeyboardAvoidingView,
	Platform,
	StyleSheet,
	Text,
	View,
} from 'react-native';
import tw from './lib/tailwind';
import FirstOpenIndex from './screens/firstOpen';
import {useDeviceContext} from 'twrnc';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import LoginContainer from './screens/login/loginContainer';
import StartScreen from './screens/start';
import Index from './screens/authenticatedScreens';
import {Provider} from 'react-redux';
import {store} from './store';
import ResetPassword from './screens/login/resetPassword';
import SubApp from './subApp';
import {registerRootComponent} from 'expo';
import Axios from 'axios';

export default function App() {
	useDeviceContext(tw);

	useEffect(() => {
		if (Platform.OS === 'web') {
			/*const tracker = new Tracker({
				projectKey: 'Ij7Q8YGQF30yIt0PVNYQ',
				ingestPoint: 'https://analytics.henomedia.de/ingest',
			});
			tracker.use(
				trackerAxios({
					instance: Axios,
				})
			);
			tracker.start();*/
		} else {
			//LogRocket.init('wgnavk/ubring');
		}
	}, []);

	return (
		<Provider store={store}>
			<SubApp />
		</Provider>
	);
}

//registerRootComponent(App);
