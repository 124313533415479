import {View, Text, Platform} from 'react-native';
import React, {createRef, useEffect, useRef, useState} from 'react';
import {Callout, Circle, Marker} from 'react-native-maps';
import MapView from 'react-native-map-clustering';
import MarkerHome from '../../../assets/images/icons/map/Home_MapIcon.png';
import MarkerPackage from '../../../assets/images/icons/map/Package_MapIcon.png';
import MarkerFinish from '../../../assets/images/icons/map/Finish_MapIcon.png';
import tw from '../../../lib/tailwind';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {selectToken} from '../../../slices/userSlice';
import {Image} from 'react-native';
import BackButton from '../../../uiKit/backButton';
import MapViewDirections from 'react-native-maps-directions';

export default function ShowPackages({route, navigation}) {
	const data = route.params.data;

	const [packages, setPackages] = useState([]);

	const token = useSelector(selectToken);

	const [routes, setRoutes] = useState([]);
	let packagesRef = packages.map(() => createRef());

	console.log('Render');

	useEffect(() => {
		let id = data.id ? data.id.toString() : data.data.id;

		axios
			.post(
				process.env.APIURL + 'shipments/alongRoute',
				{
					routeID: id,
				},
				{
					headers: {'Authorization': `Bearer ${token}`},
				}
			)
			.then((res) => {
				if (res.data) {
					console.log(JSON.stringify(res.data));
					setPackages(res.data);
					// getRoutesForShipments(res.data).then((res) => {
					// 	setRoutes(res);
					// });
				}
			})
			.catch((e) => console.log(e.response.data));
	}, [token, data]);

	async function getRoutesForShipments(packages) {
		// TODO
		// create temp route
		// for each package get create routes from origin to location start, location start to location end, location end to destination

		let tempRoutes = [];

		for (let i = 0; i < packages.length; i++) {
			let packageItem = packages[i];

			let tempRoute = [];

			let startToStartPackage = {
				start: {
					latitude: parseFloat(data.originAddress.latitude),
					longitude: parseFloat(data.originAddress.longitude),
				},
				end: {
					latitude: parseFloat(packageItem.sender.originAddress.latitude),
					longitude: parseFloat(packageItem.sender.originAddress.longitude),
				},
			};

			let startToEndPackage = {
				start: {
					latitude: parseFloat(packageItem.sender.originAddress.latitude),
					longitude: parseFloat(packageItem.sender.originAddress.longitude),
				},
				end: {
					latitude: parseFloat(packageItem.sender.destinationAddress.latitude),
					longitude: parseFloat(
						packageItem.sender.destinationAddress.longitude
					),
				},
			};

			let endToDestination = {
				start: {
					latitude: parseFloat(packageItem.sender.destinationAddress.latitude),
					longitude: parseFloat(
						packageItem.sender.destinationAddress.longitude
					),
				},
				end: {
					latitude: parseFloat(data.destinationAddress.latitude),
					longitude: parseFloat(data.destinationAddress.longitude),
				},
			};

			tempRoute.push(startToStartPackage);
			tempRoute.push(startToEndPackage);
			tempRoute.push(endToDestination);

			tempRoutes.push(tempRoute);
		}

		return tempRoutes;
	}

	return (
		<>
			<MapView
				initialRegion={{
					latitude: parseFloat(data.originAddress.latitude),
					longitude: parseFloat(data.originAddress.longitude),
					latitudeDelta: 0.5,
					longitudeDelta: 0.5,
				}}
				loadingIndicatorColor={tw.color('primary')}
				loadingBackgroundColor={tw.color('gray-300')}
				mapType="standard"
				style={tw.style('flex-1')}
				spiralEnabled
				radius={150}
				extent={1024}
			>
				{routes.map((route, index) => {
					return (
						<>
							{route.map((routeItem, index) => {
								return (
									<MapViewDirections
										origin={routeItem.start}
										destination={routeItem.end}
										apikey={process.env.GOOGLE_API_KEY}
										strokeColor={tw.color('accent')}
										strokeWidth={2}
										mode="DRIVING"
										key={index}
									/>
								);
							})}
						</>
					);
				})}

				{routes.length == 0 && (
					<MapViewDirections
						origin={{
							latitude: parseFloat(data.originAddress.latitude),
							longitude: parseFloat(data.originAddress.longitude),
						}}
						destination={{
							latitude: parseFloat(data.destinationAddress.latitude),
							longitude: parseFloat(data.destinationAddress.longitude),
						}}
						apikey={process.env.GOOGLE_API_KEY}
						strokeColor={tw.color('accent')}
						strokeWidth={2}
						mode="DRIVING"
					/>
				)}

				<MapView.Marker
					coordinate={{
						latitude: parseFloat(data.originAddress.latitude),
						longitude: parseFloat(data.originAddress.longitude),
					}}
					key="Home"
					icon={Platform.OS === 'web' && MarkerHome}
					cluster={false}
					zIndex={0}
					style={tw.style('z-0')}
					onPress={() => {}}
					tracksViewChanges={false}
					image={MarkerHome}
				>
					{/* <Image source={MarkerHome} resizeMode="contain" /> */}
				</MapView.Marker>
				<MapView.Marker
					coordinate={{
						latitude: parseFloat(data.destinationAddress.latitude),
						longitude: parseFloat(data.destinationAddress.longitude),
					}}
					key="Finish"
					icon={Platform.OS === 'web' && MarkerFinish}
					cluster={false}
					zIndex={0}
					style={tw.style('z-0')}
					tracksViewChanges={false}
					onPress={() => {}}
					image={MarkerFinish}
				>
					{/* <Image source={MarkerFinish} resizeMode="contain" /> */}
				</MapView.Marker>
				{packages.map((packageItem, index) => {
					var formatedPrice = packageItem.price / 100;
					formatedPrice = formatedPrice.toFixed(2).replace('.', ',') + ' €';

					return (
						<MapView.Marker
							coordinate={{
								latitude: parseFloat(packageItem.latitude),
								longitude: parseFloat(packageItem.longitude),
							}}
							key={index}
							onPress={() =>
								navigation.push('packageInfo', {
									data: {
										packageData: packageItem,
										routeID: data.id ? data.id.toString() : data.data.id,
										...data,
									},
								})
							}
							icon={Platform.OS === 'web' && MarkerPackage}
							zIndex={10 + index}
							tracksViewChanges={false}
							ref={packagesRef[index]}
							// image={MarkerPackage}
						>
							<View style={tw.style('flex items-center')}>
								<View
									style={tw.style('bg-white rounded-lg p-3 shadow-md mb-1.5')}
								>
									<Text>{formatedPrice}</Text>
								</View>
								<Image
									source={MarkerPackage}
									resizeMode="contain"
									onLoad={() => {
										packagesRef[index].current.redraw();
									}}
								/>
							</View>
						</MapView.Marker>
					);
				})}
			</MapView>
			<BackButton
				onPress={() =>
					navigation.replace('route', {
						merge: true,
					})
				}
				extraStyles={'absolute bottom-5 right-5'}
			/>
		</>
	);
}
