import { View, Text } from 'react-native';
import React from 'react';

export default function WaitingForBringer() {
    return (
        <View>
            <Text>Dieses Paket hat leider noch keinen Bringer</Text>
        </View>
    );
}
